import { companies } from "../url";
import axios from "axios";

export const getAllCompanies = async () => {
    return await axios.get(companies.getAllCompanies);
}

export const createCompany = async (data) => {
    return await axios.post(companies.createCompany, data);
};

export const updateCompany = async (data) => {
    return await axios.put(companies.updateCompany, data);
};

export const deleteCompany = async (id) => {
    return await axios.delete(companies.deleteCompany + '?id=' + id);
};

export const attachUser = async (data) => {
    return await axios.post(companies.attachUser, data);
};

export const detachUser = async (data) => {
    return await axios.delete(companies.detachUser + '?userId=' + data.userId + '&companyId=' + data.companyId);
};

export const attachModule = async (data) => {
    return await axios.post(companies.attachModule, data);
};

export const detachModule = async (data) => {
    return await axios.post(companies.detachModule, data);
};

export const getModulesByCompany = async (id) => {
    return await axios.get(companies.getModulesByCompany + `?companyId=${id}`);
};

export const setCompanySettings = async (data) => {
    return await axios.post(companies.setCompanySettings, data);
};

export const getCompanySettings = async (data) => {
    return await axios.get(companies.getCompanySettings + `?companyId=${data.companyId}&moduleId=${data.moduleId}`);
};

export const getCompaniesModules = async (data) => {
    return await axios.post(companies.getCompaniesModules, data);
};



