import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import userReducer from "./user/userSlice";
import companyReducer from "./company/companySlice";
import moduleReducer from "./modules/moduleSlice";
import lucaReducer from "./modules/lucaSlice";
import parasutReducer from "./modules/parasutSlice";
import rapidReducer from "./modules/rapidSlice";
import datasoftReducer from "./modules/datasoftSlice";
import rolesReducer from "./roles/rolesSlice";
import zenomReducer from "./modules/zenomSlice";

export default configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        company: companyReducer,
        modules: moduleReducer,
        luca: lucaReducer,
        parasut: parasutReducer,
        rapid: rapidReducer,
        datasoft: datasoftReducer,
        zenom: zenomReducer,
        roles: rolesReducer
    }
})
