import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import { getFilterCompany } from '../utils/util';
import { getAllCompaniesAsyncThunk } from '../../../store/company/companySlice';
import { MultiSelect } from 'primereact/multiselect';

export default function AddCompanyDialog({ visible, selectedUser, onHide, onSubmit }) {
    const { companyList } = useSelector((state) => state.company);
    const [selectedCompanies, setSelectedCompanies] = useState(null);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (visible && companyList.length === 0) {
            dispatch(getAllCompaniesAsyncThunk());
        } else {
            setSelectedCompanies(null);
        }
        if (companyList.length > 0) {
            setLoading(false);
        }
    }, [visible, dispatch, companyList]);

    const dialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" outlined onClick={() => onHide()} />
            <Button label="Bağla" icon="pi pi-user-plus" onClick={(e) => handleSubmit()} type="submit" disabled={!selectedCompanies} />
        </React.Fragment>
    );

    const handleSubmit = () => {
        onSubmit(selectedCompanies);
        setSelectedCompanies(null);
    };

    if (!visible) {
        return null;
    }

    return (
        <Dialog visible={visible} style={{ width: '62rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Şirket Bağla" modal className="p-fluid" footer={dialogFooter} onHide={() => onHide()}>
            <div className="field">
                <label htmlFor="Şirket Listesi" className="font-bold">
                    Şirket Listesi
                </label>
                <MultiSelect
                    filter
                    emptyFilterMessage="Şirket bulunamadı"
                    emptyMessage="Şirket bulunamadı"
                    placeholder={loading ? 'Şirketler Getiriliyor...' : 'Şirket Seçiniz'}
                    display="chip"
                    value={selectedCompanies}
                    onChange={(e) => setSelectedCompanies(e.value)}
                    options={getFilterCompany(selectedUser, companyList)}
                    disabled={loading}
                />
            </div>
        </Dialog>
    );
}
