import React from 'react';
import { Tag } from 'primereact/tag';

export const roleBadgeTemplate = (rowData) => {
    let roles = [];
    rowData?.roles?.map((role) => {
        if (role?.name === 'Yönetici') {
            return roles.push({ severity: 'danger', value: role?.name });
        } else {
            return roles.push({ severity: 'success', value: role?.name });
        }
    });

    return (
        <React.Fragment>
            {roles.map((role, index) => (
                <Tag key={index} value={role.value} severity={role.severity} className="mr-2 text-white" />
            ))}
        </React.Fragment>
    );
};

export const filterRole = (roles, selectedUser) => {
    const copy = [];
    roles?.forEach((role) => {
        if (!selectedUser?.roles?.some((userRole) => userRole.id === role.id)) {
            copy.push({ label: role.name, value: role.id });
        }
    });
    return copy;
};

export const getFilterCompany = (selectedUser, companyList) => {
    const userComp = selectedUser?.companies;
    const copy = [];
    companyList.forEach((company) => {
        if (!userComp.some((comp) => comp.id === company.id)) {
            copy.push({ label: company.name.length > 90 ? company.name.substring(0, 90) + '...' : company.tax_number + ' - ' + company.name, value: company.id });
        }
    });
    return copy;
};
