import biengif from '../../../../assets/flags/BienMovieGif.gif';

export default function Loading({ loading, onChangeLoading }) {
    return (
        <div className="card flex justify-content-center align-items-center h-full">
            <div className="flex flex-col justify-content-center align-items-center">
                <img width={180} height={80} src={biengif} />
            </div>
        </div>
    );
}
