export const permissionsCount = (selectedKeys) => {
    let count = 0;
    Object.keys(selectedKeys || {}).forEach((key) => {
        const value = selectedKeys[key];
        if (value.checked === true) {
            count++;
        }
    });
    return count;
};

export const getPermissionNodes = (allPermissions) => {
    const nodes = [];
    allPermissions.forEach((permission) => {
        const node = {
            key: permission.id,
            label: permission.name,
            data: permission,
            children: permission.children.map((child) => ({
                key: child.id,
                label: child.name,
                data: child.permission,
                icon: 'pi pi-fw pi-cog'
            }))
        };

        nodes.push(node);
    });
    return nodes;
}

export const getPermissionIds = (selectedKeys) => {
    let checkedIds = [];
        Object.keys(selectedKeys).forEach((key) => {
            const value = selectedKeys[key];
            if (value.checked === true) {
                checkedIds.push(parseInt(key));
            }
        });

    return checkedIds;
}
