import React from 'react';
import { useNavigate } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { useThemeContext } from './context/useThemeContext';

import { removeToken, removeUser } from './service/tokenServices';
import AppMenu from './AppMenu';

const AppTopbar = (props) => {
    const { colorScheme, onColorSchemeChange } = useThemeContext();

    const onTopbarSubItemClick = (event) => {
        event.preventDefault();
    };

    const navigate = useNavigate();

    const handleChangeTheme = () => {
        if (colorScheme === "light") {
            onColorSchemeChange("dark");
        } else {
            onColorSchemeChange("light");
        }
    }

    const handleLogout = (e) => {
        e.preventDefault();
        removeToken();
        removeUser();
        window.location.reload();
    };

    return (
        <>
            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <button className="topbar-menu-button p-link mr-4" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>

                    <button className="p-link" onClick={() => navigate('/dashboard')}>
                        <img src={`assets/layout/images/bien.png`} className="app-name flex w-auto h-3rem" alt="app-name" />
                    </button>
                </div>

                <AppMenu
                    model={props.items}
                    menuMode={props.menuMode}
                    colorScheme={props.colorScheme}
                    menuActive={props.menuActive}
                    activeInlineProfile={props.activeInlineProfile}
                    onSidebarMouseOver={props.onSidebarMouseOver}
                    onSidebarMouseLeave={props.onSidebarMouseLeave}
                    toggleMenu={props.onToggleMenu}
                    onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                    onMenuClick={props.onMenuClick}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                    onMenuItemClick={props.onMenuItemClick}
                />

                <div className="layout-topbar-right">
                    <ul className="layout-topbar-right-items">
                        <li id="profile" className={classNames('profile-item', { 'active-topmenuitem': props.topbarMenuActive })}>
                            <button className="p-link" onClick={props.onTopbarItemClick}>
                                <img src="assets/layout/images/dashboard/user.png" alt="profile" />
                            </button>

                            <ul className="fadeInDown">
                                <li role="menuitem">
                                    <button className="p-link" onClick={onTopbarSubItemClick} style={{ margin: 0, paddingLeft: '.5rem' }}>
                                        <i className="pi pi-fw pi-user"></i>
                                        <span>Profil</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link" onClick={onTopbarSubItemClick} style={{ margin: 0, paddingLeft: '.5rem' }}>
                                        <i className="pi pi-fw pi-cog"></i>
                                        <span>Ayarlar</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link" onClick={(e) => handleLogout(e)} style={{ margin: 0, paddingLeft: '.5rem' }}>
                                        <i className="pi pi-fw pi-sign-out"></i>
                                        <span>Çıkış Yap</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <button className="p-link" onClick={() => handleChangeTheme()}>
                                {colorScheme === 'light' ? <i className="topbar-icon pi pi-fw pi-moon"></i> : <i className="topbar-icon pi pi-fw pi-sun"></i>}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default AppTopbar;
