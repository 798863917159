import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import { useSelector, useDispatch } from 'react-redux';

import { getFilterModule } from '../utils/utils';
import { getAllModulesAsyncThunk } from '../../../store/modules/moduleSlice';


export default function AttachModuleDialog({ visible, onHide, onSubmit }) {
    const { moduleList } = useSelector((state) => state.modules);
    const [selectedModule, setSelectedModule] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (visible && moduleList.length === 0) {
            dispatch(getAllModulesAsyncThunk());
        }
    }, [visible, moduleList, dispatch]);

    const userDialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" outlined onClick={() => onHide()} />
            <Button label="Bağla" icon="pi pi-user-plus" onClick={(e) => handleSubmit()} type="submit" disabled={!selectedModule} />
        </React.Fragment>
    );

    if (!visible) {
        return null;
    }

    const handleSubmit = () => {
        onSubmit(selectedModule);
        setSelectedModule(null);
    }

    return (
        <Dialog
            visible={visible}
            style={{ width: '32rem' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Modül Bağla" modal
            className="p-fluid"
            footer={userDialogFooter}
            onHide={() => onHide()}>
            <div className="field">
                <label htmlFor="Modül Listesi" className="font-bold">
                    Modül Listesi
                </label>
                <Dropdown
                    emptyFilterMessage="Modül bulunamadı"
                    emptyMessage="Modül bulunamadı"
                    id="Modül Listesi"
                    filter
                    value={selectedModule}
                    options={getFilterModule(moduleList)}
                    onChange={(e) => setSelectedModule(e.value)}
                    placeholder="Modül Listesi"
                />
            </div>

        </Dialog>
    )
}