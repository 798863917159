import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { convertReq, convertName } from '../utils/util';
import { updateWebServiceReq, setWebservicesReq } from '../../../../store/modules/rapidSlice';

import { InputText } from 'primereact/inputtext';
import { setSelectedModule } from '../../../../store/modules/moduleSlice';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { getAllWebServicesByModuleId } from '../../../../service/request/moduleRequest';
import Loading from "./Loading.js"

export default function WebServicesReq({ req = null, title = null, moduleId = null }) {
    const { selectedModule, module } = useSelector((state) => state.modules);
    const { webServiceReq } = useSelector((state) => state.rapid);
    const [selectedWebService, setSelectedWebService] = useState(null);
    const [webServicesList, setWebServicesList] = useState([]);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    const getWebServices = async () => {
        const res = await getAllWebServicesByModuleId(module?.id || moduleId);
        setWebServicesList(res.data.data);
        setLoading(false);
    }

    useEffect(() => {
        if (module || moduleId) {
            getWebServices();
        }

        if (!selectedWebService) {
            dispatch(setWebservicesReq(null));
            dispatch(setSelectedModule(null));
        }
    }, [dispatch]);

    useEffect(() => {
        if (req && title && webServicesList.length > 0) {
            let selected = webServicesList.find((ws) => ws.title === title);
            setSelectedWebService(selected.id);
            dispatch(setWebservicesReq(req));
            dispatch(setSelectedModule(selected));

        }
    }, [dispatch, req, title, webServicesList]);

    const onInputChange = (value) => {
        setSelectedWebService(value);
        let copy = webServicesList.find((ws) => ws.id === value);
        dispatch(setSelectedModule(copy));
        dispatch(setWebservicesReq(convertReq(copy.requirements.split(','))));
    };

    const onServiceInputChange = (e, title) => {
        dispatch(updateWebServiceReq({ title, value: e.target.value }));
    };

    if (loading) {
        return <div className='w-full'>
            <Loading />
        </div>
    }

    const getWebServiceReqKeys = () => {
        if (req && req === webServiceReq) {
            return Object.keys(webServiceReq).reverse();
        }

        return Object.keys(webServiceReq)
    }

    return (
        <div className="w-full">
            <div>
                {!selectedWebService && <Message severity="warn" className="block mb-3 " text="Lütfen Web Servis Programı Seçiniz" />}
                <Dropdown
                    emptyFilterMessage="Program bulunamadı"
                    emptyMessage="Program bulunamadı"
                    id="Program Listesi"
                    value={selectedWebService}
                    options={webServicesList.map((program) => ({ label: program.title, value: program.id })) || []}
                    onChange={(e) => onInputChange(e.value)}
                    placeholder="Program Listesi"
                    className="w-full"
                    filter
                />
            </div>
            <div className="mt-5">
                <h3>{selectedModule?.title || title}</h3>
                {webServiceReq &&
                    getWebServiceReqKeys()?.map((req, i) => {
                        return (
                            <div key={i} className="py-3">
                                <div className="w-full mb-2">
                                    <label htmlFor={req}>{convertName(req)}</label>
                                </div>
                                <div className="w-full">
                                    <InputText onChange={(e) => onServiceInputChange(e, req)} placeholder={convertName(req)} value={webServiceReq[req]} type="text" className="w-full" />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
