import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { createCompanyAsyncThunk } from '../../store/company/companySlice';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';

let emptyCompany = {
    userId: '',
    name: '',
    taxNumber: '',
    taxOffice: '',
    email: '',
    phone: '',
    address: ''
};

export default function AddDialog({ show, hideDialog, onCreateCompany, userId = null }) {
    const [company, setCompany] = useState(emptyCompany);
    const [submitted, setSubmitted] = useState(false);
    const [user, setUser] = useState(null);
    const { users } = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const onInputChange = (e, name) => {
        const val = e.target.value;
        let _company = { ...company };
        _company[`${name}`] = val;
        setCompany(_company);
    };

    const hide = () => {
        hideDialog();
        setCompany(emptyCompany);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!company.name.trim()) {
            setSubmitted(true);
            return;
        }
        if (!company.taxNumber.trim()) {
            setSubmitted(true);
            return;
        }
        /*if (!company.taxOffice.trim()) {
            setSubmitted(true);
            return;
        }
        if (!company.email.trim()) {
            setSubmitted(true);
            return;
        }
        if (!company.phone.trim()) {
            setSubmitted(true);
            return;
        }
        if (!company.address.trim()) {
            setSubmitted(true);
            return;
        }*/
        /* if (userId) {
            company.userId = userId;
        } else {
            if (!user) {
                setSubmitted(true);
                return;
            }
            company.userId = user.id;
        } */
        hideDialog();
        await dispatch(createCompanyAsyncThunk(company));
        setCompany(emptyCompany);
        setSubmitted(false);
    };

    const companyDialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" outlined onClick={() => hide()} />
            <Button label="Kaydet" icon="pi pi-check" onClick={(e) => handleSubmit(e)} type="submit" />
        </React.Fragment>
    );

    return (
        <Dialog visible={show} style={{ width: '36rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Şirket Ekle" modal className="p-fluid" footer={companyDialogFooter} onHide={() => hide()}>
            <form onSubmit={handleSubmit}>
                {/* {userId == null && (
                    <div className="field">
                        <label className="font-bold">Kullanıcı</label>
                        <Dropdown dataKey="id" value={user} onChange={(e) => setUser(e.value)} options={users} optionLabel="name" placeholder="Kullanıcı Seçiniz" filter className="w-full mr-4 " />
                        {submitted && !user.name && <small className="p-error">Şirket Adı gerekli.</small>}
                    </div>
                )} */}
                <div className="field">
                    <label className="font-bold">Şirket Adı</label>
                    <InputText value={company.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !company.name })} placeholder="Şirket Adı" />
                    {submitted && !company.name && <small className="p-error">Şirket Adı gerekli.</small>}
                </div>
                <div className="field">
                    <label className="font-bold">Vergi Numarası</label>
                    <InputText value={company.taxNumber} onChange={(e) => onInputChange(e, 'taxNumber')} required className={classNames({ 'p-invalid': submitted && !company.taxNumber })} placeholder="Vergi Numarası" />
                    {submitted && !company.taxNumber && <small className="p-error">Vergi Numarası gerekli.</small>}
                </div>
                <div className="field">
                    <label className="font-bold">Vergi Dairesi</label>
                    <InputText value={company.taxOffice} onChange={(e) => onInputChange(e, 'taxOffice')} placeholder="Vergi Dairesi" />
                    {/*{submitted && !company.taxOffice && <small className="p-error">Vergi Dairesi gerekli.</small>}*/}
                </div>
                <div className="field">
                    <label className="font-bold">E-Mail</label>
                    <InputText value={company.email} onChange={(e) => onInputChange(e, 'email')} placeholder="E-Mail" />
                    {/*{submitted && !company.email && <small className="p-error">E-mail gerekli.</small>}*/}
                </div>
                <div className="field">
                    <label className="font-bold">Telefon</label>
                    <InputText value={company.phone} onChange={(e) => onInputChange(e, 'phone')} placeholder="Telefon" />
                    {/*{submitted && !company.phone && <small className="p-error">Telefon gerekli.</small>}*/}
                </div>
                <div className="field">
                    <label className="font-bold">Adres</label>
                    <InputText value={company.address} onChange={(e) => onInputChange(e, 'address')} placeholder="Adres" />
                    {/*{submitted && !company.address && <small className="p-error">Adres gerekli.</small>}*/}
                </div>
            </form>
        </Dialog>
    );
}
