import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';

import { useSelector, useDispatch } from 'react-redux';
import { getAllUsersAsyncThunk, clearToast } from '../../store/user/userSlice';

import DeleteDialog from './DeleteDialog';
import AddDialog from './AddDialog';
import EditDialog from './EditDialog';
import { Dialog } from 'primereact/dialog';
import {
    attachUserAsyncThunk,
    detachUserAsyncThunk,
    deleteRoleFromUserAsyncThunk,
    setRoleFromUserAsyncThunk
} from '../../store/user/userSlice';
import { usePermissionContext } from '../../context/usePermissionContext';

import { roleBadgeTemplate } from './utils/util';

import CommonDeleteDialog from './components/CommonDeleteDialog';
import AddRoleDialog from './components/AddRoleDialog';
import AddCompanyDialog from './components/AddCompanyDialog';
import Loading from '../module/datasoft/components/Loading';
import { getAllCompaniesAsyncThunk } from '../../store/company/companySlice';

export default function ProductsDemo() {
    const { users, status, toastMessage } = useSelector((state) => state.user);
    const { controlPermission } = usePermissionContext();

    const [userDialog, setUserDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [attachCompanyDialog, setAttachCompanyDialog] = useState(false);
    const [rolesDialog, setRolesDialog] = useState(false);

    const [deleteUserDialog, setDeleteUserDialog] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [companyDialog, setCompanyDialog] = useState(false);
    const [selectedUserCompanies, setSelectedUserCompanies] = useState([]);
    const [addRoleDialog, setAddRoleDialog] = useState(false);
    const [deleteDialogData, setDeleteDialogData] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        if (users.length === 0) {
            dispatch(getAllUsersAsyncThunk());
        }
    }, [dispatch]);

    useEffect(() => {
        if (status === 'success' && !loading) {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: toastMessage, life: 3000 });
            dispatch(clearToast());
        } else if (status === 'failed' && !loading) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: toastMessage, life: 3000 });
            dispatch(clearToast());
        }
    }, [status, toastMessage, dispatch]);

    const editUser = (user) => {
        setSelectedUser({ ...user, password: '' });
        setEditDialog(true);
    };

    const selectForDelete = (user) => {
        setSelectedUser(user);
        setDeleteUserDialog(true);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button className="text-white" label="Kullanıcı Ekle" icon="pi pi-user-plus" severity="success" onClick={() => setUserDialog(true)} />
            </div>
        );
    };

    const detachCompany = async (companyId) => {
        setCompanyDialog(false);
        setDeleteDialogData(null);
        setLoading(true);
        try {
            await dispatch(detachUserAsyncThunk({ companyId: companyId, userId: selectedUserCompanies.id }));
            await dispatch(getAllUsersAsyncThunk());
            await dispatch(getAllCompaniesAsyncThunk());
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Kullanıcıdan şirket bağlantısı kaldırma işlemi başarılı.', life: 2000 });
            }, 20);
        } catch (err) {
            setTimeout(() => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: err.response.data.message, life: 2000 });
            }, 20);
        }
        setLoading(false);
        setSelectedUser(null);
        setSelectedUserCompanies(null);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {
                    controlPermission('users.update') &&
                    <Button
                        icon="pi pi-pencil"
                        tooltip="Düzenle"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded onClick={() => editUser(rowData)}
                    />
                }
                {
                    controlPermission('users.addCompany') &&
                    <Button
                        icon="pi pi-tag"
                        tooltip="Bağla"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        severity="success"
                        className="mx-2 text-white"
                        onClick={() => {
                            setSelectedUser(rowData);
                            setAttachCompanyDialog(true);
                        }}
                    />}
                {
                    controlPermission('users.role.getRoles') &&
                    <Button
                        icon="pi pi-id-card"
                        tooltip="Roller"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        severity="warning"
                        className="mr-2 text-white"
                        onClick={() => {
                            setRolesDialog(true);
                            setSelectedUser(rowData);
                        }}
                    />}
                {
                    (controlPermission('users.getCompaniesByUserId') || controlPermission('users.getCompaniesByUser')) && //Fix This
                    <Button
                        icon="pi pi-building"
                        tooltip="Bağlı Şirketler"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        severity="info"
                        className="mr-2"
                        onClick={() => {
                            setCompanyDialog(true);
                            setSelectedUserCompanies(rowData);
                        }}
                    />}
                {
                    controlPermission('users.delete') &&
                    <Button
                        icon="pi pi-trash"
                        tooltip="Sil"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded severity="danger"
                        onClick={() => selectForDelete(rowData)}
                    />
                }
            </React.Fragment>
        );
    };

    const actionSubBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {controlPermission("users.removeCompany") && <Button
                    icon="pi pi-trash"
                    tooltip="Sil"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    severity="danger"
                    onClick={() => {
                        setDeleteDialogData({
                            visible: true,
                            header: 'Şirketi Sil',
                            submitLabel: 'Bağlantıyı Kaldır',
                            submitIcon: 'pi pi-user-minus',
                            content: rowData.name + ' şirketini kullanıcıdan kaldırmak istediğinize emin misiniz?',
                            onHide: () => setDeleteDialogData(null),
                            onSubmit: () => detachCompany(rowData.id)
                        });
                    }}
                />}
            </React.Fragment>
        );
    };

    const actionRoleTemplate = (rowData) => {
        return (
            <React.Fragment>
                {controlPermission("users.role.deleteRole") &&
                    <Button
                        icon="pi pi-trash"
                        tooltip="Sil"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        severity="danger"
                        onClick={() => {
                            setDeleteDialogData({
                                visible: true,
                                header: 'Rolü Sil',
                                submitLabel: 'Rolü Kaldır',
                                submitIcon: 'pi pi-user-minus',
                                content: rowData.name + ' rolünü kullanıcıdan kaldırmak istediğinize emin misiniz?',
                                onHide: () => setDeleteDialogData(null),
                                onSubmit: () => deleteRole(rowData.id)
                            });
                        }}
                    />}
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Kullanıcılar</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
            </span>
        </div>
    );

    const attachCompanyToUser = async (companyId) => {
        setAttachCompanyDialog(false);
        setLoading(true);
        try {
            await dispatch(attachUserAsyncThunk({ companyId: companyId, userId: selectedUser.id }));
            await dispatch(getAllUsersAsyncThunk());
            await dispatch(getAllCompaniesAsyncThunk());
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Kullanıcıya şirket bağlama işlemi başarılı', life: 2000 });
            }, 20);
        } catch (err) {
            setTimeout(() => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: err.response.data.message, life: 2000 });
            }, 20);
        }
        setLoading(false);
        setSelectedUser(null);
    };

    const deleteRole = async (roleId) => {
        setRolesDialog(false);
        setDeleteDialogData(null);

        await dispatch(deleteRoleFromUserAsyncThunk({ userId: selectedUser.id, roleId: roleId }));
        await dispatch(getAllUsersAsyncThunk());
    };

    const handleAddRole = async (roleId) => {
        setAddRoleDialog(false);
        setRolesDialog(false);

        await dispatch(setRoleFromUserAsyncThunk({ userId: selectedUser.id, roleId: roleId }));
        await dispatch(getAllUsersAsyncThunk());

        setSelectedUser(null);
    }

    const roleTableHeader = () => {
        if (controlPermission("users.role.setRole")) {
            return (
                <div className="flex flex-wrap gap-2 align-items-center justify-content-end">
                    <Button className="text-white" label="Rol Ekle" icon="pi pi-user-plus" severity="success" onClick={() => setAddRoleDialog(true)} />
                </div>
            )
        }
    }

    if (status === 'loading' || loading) return <Loading />;

    return (
        <div>
            <Toast ref={toast} />
            <div>
                <div className="card">
                    <Toolbar start={leftToolbarTemplate}></Toolbar>
                </div>

                <div className="card">
                    <DataTable
                        ref={dt}
                        value={users}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{totalRecords} kullanıcıdan {first} ila {last} arası gösteriliyor"
                        globalFilter={globalFilter}
                        header={header}
                        emptyMessage="Kullanıcı Bulunamadı"
                    >
                        <Column selectionMode="multiple" exportable={false}></Column>
                        <Column field="name" header="İsim" sortable style={{ minWidth: '12rem' }}></Column>
                        <Column field="email" header="Email" sortable style={{ minWidth: '16rem' }}></Column>
                        {controlPermission("users.role.getRoles") && <Column field="roles" header="Rol" sortable body={(rowData) => roleBadgeTemplate(rowData)} style={{ minWidth: '12rem' }}></Column>}
                        <Column header="İşlemler" body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    </DataTable>
                </div>

                <Dialog header="Kullanıcı Şirketleri" visible={companyDialog} style={{ width: '60vw' }} maximizable modal contentStyle={{ height: '300px' }} onHide={() => setCompanyDialog(false)}>
                    <DataTable dataKey="id" emptyMessage="Şirket Bulunamadı" value={selectedUserCompanies?.companies || []} className="h-full">
                        <Column field="name" header="Şirket Adı" style={{ minWidth: '12rem' }} sortable></Column>
                        <Column field="tax_number" header="Vergi No" style={{ minWidth: '12rem' }} sortable></Column>
                        <Column field="tax_office" header="Vergi Dairesi" style={{ minWidth: '12rem' }} sortable></Column>
                        <Column field="email" header="E-Mail" style={{ minWidth: '12rem' }} sortable></Column>
                        <Column header="İşlemler" body={actionSubBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    </DataTable>
                </Dialog>

                <Dialog header="Roller" visible={rolesDialog} style={{ width: '60vw' }} maximizable modal contentStyle={{ height: '300px' }} onHide={() => setRolesDialog(false)}>
                    <DataTable
                        dataKey="id"
                        emptyMessage="Rol Bulunamadı"
                        value={selectedUser?.roles}
                        className="h-full"
                        header={roleTableHeader}
                    >
                        <Column field="name" header="Rol Adı" style={{ minWidth: '12rem' }} sortable></Column>
                        <Column field="created_at" header="Oluşturulma Tarihi" body={(rowData) => rowData.created_at.replaceAll('-', '.')} style={{ minWidth: '12rem' }} sortable></Column>
                        <Column header="İşlemler" body={actionRoleTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    </DataTable>
                </Dialog>

                <AddCompanyDialog
                    visible={attachCompanyDialog}
                    selectedUser={selectedUser}
                    onHide={() => setAttachCompanyDialog(false)}
                    onSubmit={attachCompanyToUser}
                />

                <AddRoleDialog
                    visible={addRoleDialog}
                    selectedUser={selectedUser}
                    onHide={() => setAddRoleDialog(false)}
                    onSubmit={handleAddRole}
                />

                <AddDialog show={userDialog} hideDialog={() => setUserDialog(false)} onCreateUser={() => setUserDialog(false)} />

                <EditDialog
                    show={editDialog}
                    hideDialog={() => setEditDialog(false)}
                    selectedUser={selectedUser}
                    onEditUser={() => {
                        setEditDialog(false);
                        setSelectedUser(null);
                    }}
                />
                <DeleteDialog
                    show={deleteUserDialog}
                    hideDialog={() => setDeleteUserDialog(false)}
                    onDeleteUser={() => {
                        setDeleteUserDialog(false);
                        setSelectedUser(null);
                    }}
                    userId={selectedUser?.id}
                    userName={selectedUser?.name}
                />

                <CommonDeleteDialog {...deleteDialogData} />
            </div>
        </div>
    );
}
