import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PermissionContext = createContext();

export const PermissionProvider = ({ children }) => {
    const { user } = useSelector((state) => state.auth);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        if(user && permissions.length === 0) {
            setPermissions(user.yetkiler);
        }
    }, [user, permissions]);

    const controlPermission = (permission) => {
        return permissions.includes(permission) 
    };

    return (
        <PermissionContext.Provider value={{ permissions, controlPermission }}>
            {children}
        </PermissionContext.Provider>
    );
};

export const usePermissionContext = () => useContext(PermissionContext);