import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { useSelector, useDispatch } from 'react-redux';
import { getAllRolesAsyncThunk } from '../../../store/roles/rolesSlice';

import { filterRole } from '../utils/util';


export default function AddRoleDialog({ visible, selectedUser, onHide, onSubmit }) {
    const { roles } = useSelector(state => state.roles);
    const [selectedRoleForUser, setSelectedRoleForUser] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (visible && roles.length === 0) {
            dispatch(getAllRolesAsyncThunk());
        }
    }, [dispatch, visible]);


    if (!visible) {
        return null;
    }

    const dialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" outlined onClick={() => onHide()} />
            <Button label="Ekle" icon="pi pi-user-plus" onClick={(e) => handleSubmit()} type="submit" disabled={!selectedRoleForUser} />
        </React.Fragment>
    );

    const handleSubmit = () => {
        onSubmit(selectedRoleForUser);
        setSelectedRoleForUser(null);
    }

    return (
        <Dialog
            visible={visible}
            style={{ width: '32rem' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Rol Ekle"
            modal
            className="p-fluid"
            footer={dialogFooter}
            onHide={() => onHide()}
        >
            <div className="field">
                <label htmlFor="Roller" className="font-bold">
                    Roller
                </label>
                <Dropdown
                    emptyFilterMessage="Rol bulunamadı"
                    emptyMessage="Rol bulunamadı"
                    id="Roller Listesi"
                    value={selectedRoleForUser}
                    options={filterRole(roles, selectedUser) || []}
                    filter
                    onChange={(e) => setSelectedRoleForUser(e.value)}
                    placeholder="Roller Listesi"
                />
            </div>
        </Dialog>
    )
}
