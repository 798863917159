import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DeleteDialog from './DeleteDialog';
import AddDialog from './AddDialog';
import EditDialog from './EditDialog';
import { getCompaniesByUserIdAsyncThunk } from '../../store/user/userSlice';

import { useNavigate } from 'react-router-dom';

import { setSelectedCompanyId, setUpdatedModule } from '../../store/modules/moduleSlice';
import DeleteModuleDialog from './components/DeleteModuleDialog';
import Loading from '../module/datasoft/components/Loading';

export default function UserCompany() {
    const { userCompanies, error, status } = useSelector((state) => state.user);

    const [companyDialog, setCompanyDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);

    const { id } = useParams();

    const [deleteCompanyDialog, setDeleteCompanyDialog] = useState(false);
    const [deleteModuleDialog, setDeleteModuleDialog] = useState(false);

    const { companyList } = useSelector((state) => state.company);

    const [expandedRows, setExpandedRows] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedModule, setSelectedModule] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const { users } = useSelector((state) => state.user);
    const navigate = useNavigate();

    const toast = useRef(null);
    const dt = useRef(null);
    const dispatch = useDispatch();

    const deleteModule = () => {
        setDeleteModuleDialog(false);
        setSelectedModule(null);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Modül Silindi', life: 3000 });
    };

    useEffect(() => {
        dispatch(getCompaniesByUserIdAsyncThunk(id));
    }, [dispatch]);

    const selectCompany = (company) => {
        setSelectedCompany({ ...company });
        setEditDialog(true);
    };

    const selectForDelete = (company) => {
        setSelectedCompany(company);
        setDeleteCompanyDialog(true);
    };

    const selectForModuleDelete = (module) => {
        setSelectedModule(module);
        setDeleteModuleDialog(true);
    };

    const rightToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Şirket Ekle" icon="pi pi-plus" severity="success" onClick={() => setCompanyDialog(true)} />
            </div>
        );
    };
    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap">
                <h4 className="mb-0">Şirketleri Gösterilen Kullanıcı: {users.find((u) => u._id === id)?.name}</h4>
            </div>
        );
    };

    const navigateToModules = (rowData) => {
        dispatch(setSelectedCompanyId(rowData._id));
        navigate(`/modules`);
    };

    const navigateToMatch = (rowData) => {
        dispatch(setUpdatedModule({ companyId: Object.keys(expandedRows)[0], module: rowData }));
        const programTitle = rowData.program.title.split(' ')[0];
        navigate(`/matchmodulesupdate/${rowData.title}/${programTitle}`);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" tooltip="Düzenle" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded outlined onClick={() => selectCompany(rowData)} />
                <Button icon="pi pi-tag" tooltip="Bağla" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} className="mx-2" rounded outlined severity="success" onClick={() => navigateToModules(rowData)} />
                <Button icon="pi pi-trash" tooltip="Sil" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded outlined severity="danger" onClick={() => selectForDelete(rowData)} />
            </React.Fragment>
        );
    };

    const actionModulesTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    tooltip="Düzenle"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded outlined onClick={() => navigateToMatch(rowData)} />
                <Button
                    icon="pi pi-trash"
                    tooltip="Sil"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    className="mx-2"
                    rounded
                    outlined
                    severity="danger" onClick={() => selectForModuleDelete(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Şirketler</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
            </span>
        </div>
    );

    const createCompany = () => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Şirket Eklenemedi', life: 3000 });
        } else {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Eklendi', life: 3000 });
        }
        setCompanyDialog(false);
        dispatch(getCompaniesByUserIdAsyncThunk(id));
    };

    const editCompany = () => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Şirket Güncellenemedi', life: 3000 });
        } else {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Güncellendi', life: 3000 });
        }
        setEditDialog(false);
        setSelectedCompany(null);
        dispatch(getCompaniesByUserIdAsyncThunk(id));
    };

    const deleteCompany = () => {
        setDeleteCompanyDialog(false);
        setSelectedCompany(null);
        dispatch(getCompaniesByUserIdAsyncThunk(id));
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Silindi', life: 3000 });
    };

    const rowExpansionTemplate = (rowData) => {
        return (
            <div className="p-3">
                <h5>{rowData.name} Modülleri</h5>
                <DataTable emptyMessage="Modül Bulunamadı" value={rowData.modules}>
                    <Column field="title" header="Modül" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="program.title" header="Program" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="webService.title" header="Web Servisi" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column header="İşlemler" body={actionModulesTemplate} exportable={false}></Column>
                    <Column headerStyle={{ width: '4rem' }}></Column>
                </DataTable>

                <DeleteModuleDialog userId={id} show={deleteModuleDialog} module={selectedModule} company={Object.keys(expandedRows)} hideDialog={() => setDeleteModuleDialog(false)} onDeleteModule={deleteModule} />
            </div>
        );
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="card">
                <Toolbar className="mb-4 w-full" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

                <DataTable
                    ref={dt}
                    value={userCompanies}
                    dataKey="_id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{totalRecords} şirketten {first} ila {last} arası gösteriliyor"
                    globalFilter={globalFilter}
                    header={header}
                    emptyMessage="Şirket Bulunamadı"
                    rowExpansionTemplate={rowExpansionTemplate}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                >
                    <Column expander style={{ width: '5rem' }} />
                    <Column field="name" header="Şirket İsmi" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="taxNumber" header="Vergi Numarası" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="taxOffice" header="Vergi Dairesi" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="phone" header="Telefon" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="email" header="E-Mail" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="address" header="Adres" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column header="İşlemler" body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>

                <AddDialog userId={id} show={companyDialog} hideDialog={() => setCompanyDialog(false)} onCreateCompany={createCompany} />
                <EditDialog show={editDialog} hideDialog={() => setEditDialog(false)} selectedCompany={selectedCompany} onEditCompany={editCompany} />
                <DeleteDialog show={deleteCompanyDialog} companyId={selectedCompany?._id} name={selectedCompany?.name} hideDialog={() => setDeleteCompanyDialog(false)} onDeleteCompany={deleteCompany} />
            </div>
        </div>
    );
}
