import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useSelector, useDispatch } from 'react-redux';
import { getAllModulesAsyncThunk } from '../../../store/modules/moduleSlice';

export default function CategoryActionDialog({ selectedCategory,visible, onClose, onCreate }) {
    const { moduleList } = useSelector((state) => state.modules);
    const dispatch = useDispatch();
    const [name, setName] = useState(selectedCategory?.name || '');
    const [selectedModule, setSelectedModule] = useState(selectedCategory?.module || null);

    useEffect(() => {
        if (moduleList.length === 0) {
            dispatch(getAllModulesAsyncThunk());
        }

        setName(selectedCategory?.name || '');
        setSelectedModule(selectedCategory?.module || null);
    }, [selectedCategory, moduleList, dispatch]);

    const handleCreate = () => {
        onCreate({
            name,
            module_id: selectedModule.id
        });
        setName('');
        setSelectedModule(null);
    }

    if (!visible) return null


    return (
        <Dialog header="Kategori İşlemleri" visible={visible} style={{ width: '36vw' }} modal onHide={() => onClose()}>
            <div>
                <div className="w-full mb-2">
                    <label>Kategori Adı</label>
                </div>
                <div className="w-full">
                    <InputText className="w-full mb-4" type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Kategori Adı" required />
                </div>
                <div className="field">
                    <label className="font-bold">Modül Adı</label>
                    <Dropdown
                        dataKey="id"
                        value={selectedModule}
                        onChange={(e) => setSelectedModule(e.value)}
                        options={moduleList.map((module) => {
                            return {
                                id: module.id,
                                name: module.title
                            }
                        })}
                        optionLabel="name"
                        placeholder="Modül Seçiniz"
                        filter
                        className="w-full mr-4 "
                    />
                </div>
                <div className="w-full mb-2 flex justify-content-end">
                    <Button label="Kaydet" icon="pi pi-check" onClick={() => handleCreate()} type="submit" disabled={name === "" || selectedModule === null} />
                </div>
            </div>
        </Dialog>
    )
}