export const programTemplate = (rowData) => {
    if (rowData.settings?.program) {
        return <p>{rowData.settings.program.title}</p>;
    } else {
        return <span className="p-badge p-badge-danger">Program yok</span>;
    }
};

export const filterCommercialPrograms = (commercialProgramsList, modules, selectedModule) => {
    let filterProgram = [];
    commercialProgramsList?.forEach((program) => {
        let mod = modules.find((module) => module.id === selectedModule.id);
        if (program.id !== mod?.settings?.program._id) {
            filterProgram.push({ label: program.title, value: program.id });
        }
    });

    return filterProgram;
};

export const getFilterModule = (moduleList) => {
    let filterModule = [];
    moduleList.forEach((module) => {
        filterModule.push({ label: module.title, value: module.id });
    });
    return filterModule;
};