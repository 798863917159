export const convertName = (req) => {
    switch (req) {
        case 'username':
            return 'Kullanıcı Adı';
        case 'password':
            return 'Şifre';
        case 'DealerID':
            return 'Bayi Kodu (DealerID)';
        case 'EInvoiceCode':
            return 'Güvenlik Anahtarı (EInvoiceCode)';
        case 'client_id':
            return 'Client ID';
        case 'client_secret':
            return 'Client Secret';
        case 'SystemId':
            return 'Sistem Kodu (SystemId)';
        default:
            return req;
    }
};

export const convertReq = (req) => {
    let copy = {};
    req.forEach((req) => {
        copy[req] = '';
    });

    return copy;
};
