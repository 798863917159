import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { useDispatch } from 'react-redux';
import { updateCompanyAsyncThunk } from '../../store/company/companySlice';

export default function EditDialog({ show, selectedCompany, hideDialog, onEditCompany }) {
    const [submitted, setSubmitted] = useState(false);
    const [selected, setSelected] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedCompany) {
            setSelected(selectedCompany);
        }
    }, [selectedCompany]);

    const onInputChange = (e, name) => {
        const val = e.target.value;
        let _company = { ...selected };
        _company[`${name}`] = val;
        setSelected(_company);
    };

    const hide = () => {
        hideDialog();
        setSelected(selectedCompany);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let copyCompany = { ...selected };

        if (!copyCompany.name.trim()) {
            setSubmitted(true);
            return;
        }
        if (!copyCompany.tax_number.trim()) {
            setSubmitted(true);
            return;
        }
        /*if (!copyCompany.tax_office.trim()) {
            setSubmitted(true);
            return;
        }
        if (!copyCompany.email.trim()) {
            setSubmitted(true);
            return;
        }
        if (!copyCompany.phone.trim()) {
            setSubmitted(true);
            return;
        }
        if (!copyCompany.address.trim()) {
            setSubmitted(true);
            return;
        }*/

        onEditCompany();
        await dispatch(
            updateCompanyAsyncThunk({
                name: copyCompany.name,
                taxNumber: copyCompany.tax_number,
                taxOffice: copyCompany.tax_office,
                email: copyCompany.email,
                id: copyCompany.id,
                phone: copyCompany.phone,
                address: copyCompany.address
            })
        );

        setSubmitted(false);
    };

    const companyDialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" outlined onClick={() => hide()} />
            <Button label="Kaydet" icon="pi pi-check" onClick={(e) => handleSubmit(e)} type="submit" />
        </React.Fragment>
    );

    if (!selectedCompany) {
        return null;
    }

    return (
        <Dialog visible={show} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Şirket Güncelle" modal className="p-fluid" footer={companyDialogFooter} onHide={() => hide()}>
            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label className="font-bold">Şirket Adı</label>
                    <InputText value={selected.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !selected.name })} placeholder="Şirket Adı" />
                    {submitted && !selected.name && <small className="p-error">Şirket Adı gerekli.</small>}
                </div>
                <div className="field">
                    <label className="font-bold">Vergi Numarası</label>
                    <InputText value={selected.tax_number} onChange={(e) => onInputChange(e, 'tax_number')} required className={classNames({ 'p-invalid': submitted && !selected.tax_number })} placeholder="Vergi Numarası" />
                    {submitted && !selected.taxNumber && <small className="p-error">Vergi Numarası gerekli.</small>}
                </div>
                <div className="field">
                    <label className="font-bold">Vergi Dairesi</label>
                    <InputText value={selected.tax_office} onChange={(e) => onInputChange(e, 'tax_office')} placeholder="Vergi Dairesi" />
                    {/*{submitted && !selected.taxOffice && <small className="p-error">Vergi Dairesi gerekli.</small>}*/}
                </div>
                <div className="field">
                    <label className="font-bold">E-Mail</label>
                    <InputText value={selected.email} onChange={(e) => onInputChange(e, 'email')} placeholder="E-Mail" />
                    {/*{submitted && !selected.email && <small className="p-error">E-mail gerekli.</small>}*/}
                </div>
                <div className="field">
                    <label className="font-bold">Telefon</label>
                    <InputText value={selected.phone} onChange={(e) => onInputChange(e, 'phone')} placeholder="Telefon" />
                    {/*{submitted && !selected.phone && <small className="p-error">Telefon gerekli.</small>}*/}
                </div>
                <div className="field">
                    <label className="font-bold">Adres</label>
                    <InputText value={selected.address} onChange={(e) => onInputChange(e, 'address')} placeholder="Adres" />
                    {/*{submitted && !selected.address && <small className="p-error">Adres gerekli.</small>}*/}
                </div>
            </form>
        </Dialog>
    );
}
