import axios from "axios";

import { tickets } from "../url";

export const getAllTickets = async () => {
    return await axios.get(tickets.getAll);
}

export const sendMessage = async (message) => {
    return await axios.post(tickets.sendMessage, message);
}

export const getTicketById = async (id) => {
    return await axios.get(tickets.getById + `?id=${id}`);
}

export const updateStatus = async (data) => {
    return await axios.post(tickets.updateStatus, data);
};