import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { createUserAsyncThunk, getAllUsersAsyncThunk } from '../../store/user/userSlice';
import { useDispatch } from 'react-redux';

let emptyUser = {
    name: '',
    email: '',
    role: '',
    password: ''
};

export default function AddDialog({ show, hideDialog, onCreateUser }) {
    const [user, setUser] = useState(emptyUser);
    const [submitted, setSubmitted] = useState(false);

    const dispatch = useDispatch();

    const onInputChange = (e, name) => {
        const val = e.target.value;
        let _user = { ...user };
        _user[`${name}`] = val;
        setUser(_user);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user.name.trim()) {
            setSubmitted(true);
            return;
        }
        if (!user.email.trim()) {
            setSubmitted(true);
            return;
        }
        if (!user.password.trim()) {
            setSubmitted(true);
            return;
        }
        /* if (!user.role.trim()) {
            setSubmitted(true);
            return;
        } */

        if (user.password && user.password.length < 6) {
            setSubmitted(true);
            return;
        }

        onCreateUser();
        await dispatch(createUserAsyncThunk(user));
        await dispatch(getAllUsersAsyncThunk());
        setSubmitted(false);
        setUser(emptyUser);
    };

    const userDialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Kaydet" icon="pi pi-check" onClick={(e) => handleSubmit(e)} type="submit" />
        </React.Fragment>
    );

    const options = [
        { label: 'Admin', value: 'admin' },
        { label: 'User', value: 'user' }
    ];

    if (!show) {
        if (user !== emptyUser) {
            setUser(emptyUser);
        }
        return null;
    }

    return (
        <Dialog visible={show} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Kullanıcı Ekle" modal className="p-fluid" footer={userDialogFooter} onHide={hideDialog}>
            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label htmlFor="Kullanıcı Adı" className="font-bold">
                        Kullanıcı Adı
                    </label>
                    <InputText id="name" value={user.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.name })} placeholder="Kullanıcı Adı" />
                    {submitted && !user.name && <small className="p-error">Kullanıcı Adı gerekli.</small>}
                </div>
                <div className="field">
                    <label htmlFor="E-Mail" className="font-bold">
                        E-Mail
                    </label>
                    <InputText id="E-Mail" value={user.email} onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': submitted && !user.email })} placeholder="E-Mail" />
                    {submitted && !user.email && <small className="p-error">Email gerekli.</small>}
                </div>

                <div className="field">
                    <label htmlFor="Şifre" className="font-bold">
                        Şifre
                    </label>
                    <InputText id="Şifre" value={user.password} onChange={(e) => onInputChange(e, 'password')} required placeholder="Şifre" className={classNames({ 'p-invalid': submitted && !user.password })} />
                    {submitted && !user.password && <small className="p-error">Şifre gerekli.</small>}
                    {submitted && user.password.length < 6 && <small className="p-error">Şifre 5 karakterden uzun olmalıdır.</small>}
                </div>
                {/*                 <div className="field">
                    <label htmlFor="Rol" className="font-bold">
                        Rol
                    </label>
                    <Dropdown id="Rol" value={user.role} options={options} onChange={(e) => onInputChange(e, 'role')} placeholder="Rol" className={classNames({ 'p-invalid': submitted && !user.role })} />
                    {submitted && !user.role && <small className="p-error">Rol gerekli.</small>}
                </div> */}
            </form>
        </Dialog>
    );
}
