import axios from 'axios';
import { auth } from '../url.js';

export const login = async (email, password) => {
    return await axios.post(auth.login, { email, password });
}

export const logout = async () => {
    return await axios.post(auth.logout);
};

export const register = async (email, name, password) => {
    return await axios.post(auth.register, { email, name, password });
};