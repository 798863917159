import { useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import React from "react";
import { deleteUserAsyncThunk } from "../../store/user/userSlice";

export default function DeleteDialog({ show, hideDialog, onDeleteUser, userId, userName }) {
    const dispatch = useDispatch();

    const deleteUser = async () => {
        onDeleteUser();
        await dispatch(deleteUserAsyncThunk(userId));
    };

    const deleteUserDialogFooter = (
        <React.Fragment>
            <Button label="Hayır" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Evet" icon="pi pi-check" severity="danger" onClick={deleteUser} />
        </React.Fragment>
    );

    return (
        <Dialog visible={show} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Silme" modal footer={deleteUserDialogFooter} onHide={hideDialog}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                <span>
                    <b>{userName}</b> Silmek istediğinize emin misiniz ?
                </span>
            </div>
        </Dialog>
    );
}