import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import { 
    getAllCategories, 
    createVideo, 
    createCategory, 
    deleteCategory, 
    deleteVideo, 
    updateVideo, 
    updateCategory 
} from '../../service/request/educationRequest';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CategoryActionDialog from './components/CategoryActionDialog';
import './iframe.css';
import ConfirmDialog from './components/ConfirmDialog';
import VideoActionDialog from './components/VideoActionDialog';
import { Toast } from 'primereact/toast';
import Loading from '../module/datasoft/components/Loading';
import { Dropdown } from 'primereact/dropdown';

export default function Videos() {
    const [categories, setCategories] = useState([]);
    const [videos, setVideos] = useState([]);
    const [categoriesDialog, setCategoriesDialog] = useState(false);
    const [videosDialog, setVideosDialog] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [createCategoryDialog, setCreateCategoryDialog] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({});
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const [selectedCategoryForVideo, setSelectedCategoryForVideo] = useState(null);

    const fetchAllCategories = () => {
        getAllCategories().then((response) => {
            setCategories(response.data.data);

            const copy = [];
            response.data.data.map((category) => {
                category.videos.map((video) => {
                    copy.push(video);
                });
            });
            setLoading(false);
            setVideos(copy);
        });
    };

    useEffect(() => {
        fetchAllCategories();
    }, []);

    const gridItem = (product) => {
        return (
            <div className="p-2" style={{ width: '440px' }} key={product.id}>
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-column gap-1">
                        <div dangerouslySetInnerHTML={{ __html: product.url }}></div>
                        <div className="text-4xl font-bold">{product.title}</div>
                        <div className="text-xl font-semibold">{product.subtitle}</div>
                    </div>
                    <div className="flex align-items-center justify-content-between mt-4 gap-4">
                        <Button onClick={() => {
                            setSelectedVideo(product);
                            setVideosDialog(true);
                        }}
                            icon="pi pi-replay" className="flex-1 flex justify-content-center gap-2">
                            Güncelle
                        </Button>
                        <Button onClick={() => setConfirmDialog(
                            {
                                dialogTitle: "Video Sil",
                                visible: true,
                                text: {
                                    title: product.title,
                                    content: " video silinecek. Emin misiniz?"
                                },
                                onConfirm: () => handleDeleteVideo(product.id),
                                onClose: () => setConfirmDialog({})
                            }
                        )} icon="pi pi-trash" severity="danger" className="flex-1 flex justify-content-center gap-2">
                            Sil
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const itemTemplate = (product) => {
        if (!product) {
            return;
        }
        return gridItem(product);
    };

    const listTemplate = (products) => {
        return <div className="w-full flex flex-wrap justify-content-start gap-4">{products.map((product, index) => itemTemplate(product))}</div>;
    };

    const header = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h1>Videolar</h1>
                <div className='flex align-items-center gap-4 w-4'>
                    <Dropdown
                        dataKey="id"
                        value={selectedCategoryForVideo}
                        onChange={(e) => setSelectedCategoryForVideo(e.value)}
                        appendTo="self"
                        options={categories.map((category) => {
                            return {
                                id: category.id,
                                name: category.module.title + " Modülü - " + category.name
                            }
                        })}
                        optionLabel="name"
                        placeholder="Kategori Seçiniz"
                        filter
                        className="w-full"
                    />
                    <Button label="Kategoriler" icon="pi pi-plus" onClick={() => setCategoriesDialog(true)} className='pr-5' />
                </div>
            </div>
        );
    };

    const actionCodeTemplate = (rowData) => {
        return (
            <>
                <Button
                    icon="pi pi-plus"
                    tooltip="Video Ekle"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    className="text-white"
                    rounded
                    severity="success"
                    onClick={() => {
                        setVideosDialog(true);
                        setSelectedCategory(rowData);
                    }}
                />
                <Button onClick={() => {
                    setSelectedCategory(rowData);
                    setCreateCategoryDialog(true);
                }}
                    className="mx-2 text-white"
                    rounded
                    severity="info"
                    tooltip="Kategori Güncelle"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    icon="pi pi-replay" />

                <Button
                    icon="pi pi-trash"
                    tooltip="Sil"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    className="text-white"
                    rounded severity="danger"
                    onClick={() => setConfirmDialog({
                        dialogTitle: "Kategori Sil",
                        visible: true,
                        text: {
                            title: rowData.name,
                            content: " kategorisi silinecek. Emin misiniz?"
                        },
                        onConfirm: () => handleDeleteCategory(rowData.id),
                        onClose: () => setConfirmDialog({})
                    })}
                />
            </>
        );
    };

    const handleVideoAction = (data) => {
        setVideosDialog(false);
        if (selectedVideo) {
            data.id = selectedVideo.id;

            updateVideo(data).then((response) => {
                fetchAllCategories();
                setSelectedVideo(null);
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Video Güncellendi', life: 3000 });
            });
        }
        else {
            data.category_id = selectedCategory.id;

            createVideo(data).then((response) => {
                fetchAllCategories();
                setSelectedCategory(null);
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Video Oluşturuldu', life: 3000 });
            });
        }
    };

    const categoriesHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h4>Kategoriler</h4>
                <Button label="Oluştur" icon="pi pi-plus" onClick={() => {setSelectedCategory(null); setCreateCategoryDialog(true)}} />
            </div>
        );
    }

    const handleCreateCategory = (data) => {
        setCreateCategoryDialog(false);
        if (selectedCategory) {
            data.id = selectedCategory.id;
            data.module_id = selectedCategory.module_id;

            updateCategory(data).then((response) => {
                fetchAllCategories();
                setSelectedCategory(null);
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Kategori Güncellendi', life: 3000 });
            });
        }
        else {
            createCategory(data).then((response) => {
                fetchAllCategories();
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Kategori Oluşturuldu', life: 3000 });
            });
        }
        setSelectedCategory(null);
    }

    const handleDeleteCategory = (id) => {
        setConfirmDialog({});
        deleteCategory(id).then((response) => {
            let categoryCopy = categories.filter((category) => category.id !== id);
            setCategories(categoryCopy);
            let videoCopy = videos.filter((video) => video.category_id !== id);
            setVideos(videoCopy);
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Kategori Silindi', life: 3000 });
        });
    }

    const handleDeleteVideo = (id) => {
        setConfirmDialog({});
        deleteVideo(id).then((response) => {
            let copy = videos.filter((video) => video.id !== id);
            setVideos(copy);
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Video Silindi', life: 3000 });
        });
    }

    if (loading) {
        return <Loading />
    }

    const getFilterVideos = () => {
        if (selectedCategoryForVideo) {
            let copy = videos.filter((video) => video.category_id === selectedCategoryForVideo.id);

            return copy.length > 0 ? [copy] : null;
        }
        return [videos];
    }

    return (
        <div className="card">
            <Toast ref={toast} />
            <DataView
                value={getFilterVideos()}
                itemTemplate={listTemplate}
                layout={"grid"} header={header()}
                emptyMessage='Video Bulunamadı'
                rows={20}
                paginator
            />
            <Dialog header="Kategoriler" visible={categoriesDialog} style={{ width: '50vw' }} maximizable modal contentStyle={{ height: '300px' }} onHide={() => setCategoriesDialog(false)}>
                <DataTable value={categories} scrollable scrollHeight="flex" tableStyle={{ minWidth: '50rem' }} emptyMessage="Kategori Bulunamadı" header={categoriesHeader}>
                    <Column field="name" header="Program Adı"></Column>
                    <Column field="module.title" header="Modül"></Column>
                    <Column header="İşlemler" body={actionCodeTemplate} exportable={false}></Column>
                </DataTable>
            </Dialog>
            <VideoActionDialog
                selectedVideo={selectedVideo}
                visible={videosDialog}
                onClose={() => {setVideosDialog(false); setSelectedVideo(null)}}
                onSubmit={handleVideoAction}
                categoryList={
                    categories.map((category) => {
                        return {
                            id: category.id,
                            name: category.name
                        }
                    })
                }
            />
            <CategoryActionDialog visible={createCategoryDialog} selectedCategory={selectedCategory} onClose={() => {setCreateCategoryDialog(false); setSelectedCategory(null)}} onCreate={handleCreateCategory} />
            <ConfirmDialog {...confirmDialog} />
        </div>
    );
}
