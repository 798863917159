import React, { useState, useRef } from 'react';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import WebServicesReq from './components/WebServicesReq';
import Loading from './components/Loading';
import ProgramReq from './components/ProgramReq';

import { clearRequirements, setModuleSettingsAsyncThunk } from '../../../store/modules/moduleSlice';

export default function Rapid() {
    const { selectedModule, selectedProgram, selectedCompanyId, module, selectedCompany } = useSelector((state) => state.modules);
    const { webServiceReq, programReq } = useSelector((state) => state.rapid);

    const [loading, setLoading] = useState(false);
    const [pending, setPending] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useRef(null);

    const save = async () => {
        const data = {
            companyId: selectedCompanyId,
            moduleId: module.id,
            settings: {
                moduleId: module.id,
                title: module.title,
                program: {
                    _id: selectedProgram.id,
                    title: selectedProgram.title,
                    requirements: [
                        {
                            client_id: programReq.client_id,
                            client_secret: programReq.client_secret,
                            DealerID: programReq.DealerID,
                            EInvoiceCode: programReq.EInvoiceCode,
                            SystemId: programReq.SystemId
                        }
                    ]
                },
                webService: {
                    requirements: webServiceReq,
                    _id: selectedModule.id,
                    title: selectedModule.title,
                    url: selectedModule.url
                }
            }
        };

        setPending(true);
        let res = await dispatch(setModuleSettingsAsyncThunk(data));

        if (res.meta.requestStatus === 'fulfilled') {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Modül Eklendi', life: 1500 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Modül Eklenemedi', life: 1500 });
        }

        setTimeout(() => {
            setPending(false);
            navigate(`/companies`);
            dispatch(clearRequirements());
        }, 500);
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const programReqState = () => {
        return programReq?.client_id && programReq?.client_secret && programReq?.DealerID && programReq?.EInvoiceCode && programReq?.SystemId;
    };

    const webServiceReqState = () => {
        return webServiceReq?.username && webServiceReq?.password;
    };

    const isDisabled = () => {
        if (programReqState() && webServiceReqState() && selectedModule !== null) {
            return false;
        }

        return true;
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="flex align-items-center">
                <Button className="" icon="pi pi-building" />
                <h3 className="mb-4 ml-3">{selectedCompany?.name}</h3>
            </div>
            <div className="card">
                <div className="">
                    <div className="flex flex-column md:flex-row">
                        <div className="w-full">
                            <ProgramReq />
                        </div>

                        <div className="w-full md:w-1">
                            <Divider layout="vertical" className="hidden md:flex"></Divider>
                        </div>

                        <WebServicesReq />
                    </div>
                </div>

                {
                    <div className="text-center mt-5">
                        <Button label="Kaydet" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => save()} disabled={isDisabled()} loading={pending} />
                    </div>
                }
            </div>
        </>
    );
}
