import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { useSelector, useDispatch } from 'react-redux';
import { getAllCompaniesAsyncThunk, getModulesByCompanyAsyncThunk, clearToast, attachModuleAsyncThunk } from '../../store/company/companySlice';

import DeleteDialog from './DeleteDialog';
import AddDialog from './AddDialog';
import EditDialog from './EditDialog';

import { setRequirements, setUpdatedModule, setSelectedCompanyAction } from '../../store/modules/moduleSlice';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';

import { usePermissionContext } from '../../context/usePermissionContext';

import { programTemplate } from './utils/utils';

import AttachModuleDialog from './components/AttachModuleDialog';
import DeleteModuleDialog from './components/DeleteModuleDialog';
import AddProgramDialog from './components/AddProgramDialog';
import Loading from '../module/datasoft/components/Loading';
import { getAllUsersAsyncThunk } from '../../store/user/userSlice';
import { Dropdown } from 'primereact/dropdown';
import { getAllUsers } from '../../service/request/userRequest';
import CommonDeleteDialog from '../user/components/CommonDeleteDialog';
import { attachUser, detachUser } from '../../service/request/companyRequest';

export default function ProductsDemo() {
    const { companyList, status, toastMessage, modules } = useSelector((state) => state.company);

    const [companyDialog, setCompanyDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);

    const [deleteCompanyDialog, setDeleteCompanyDialog] = useState(false);
    const [addProgramDialog, setAddProgramDialog] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedCompanyModuleDialog, setSelectedCompanyModuleDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [attachModuleDialog, setAttachModuleDialog] = useState(false);
    const [selectedModule, setSelectedModule] = useState(null);
    const [deleteModuleDialog, setDeleteModuleDialog] = useState(false);
    const [userDialogVisible, setUserDialogVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [attachCompanyDialog, setAttachCompanyDialog] = useState(false);
    const [deleteDialogData, setDeleteDialogData] = useState(null);
    const [usersList, setUsersList] = useState([]);
    const [loading, setLoading] = useState(false);

    const { controlPermission } = usePermissionContext();

    const toast = useRef(null);
    const dt = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (status === 'success') {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: toastMessage, life: 2000 });
            dispatch(clearToast());
        } else if (status === 'failed') {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: toastMessage, life: 2000 });
            dispatch(clearToast());
        }
    }, [status, toastMessage, dispatch]);

    useEffect(() => {
        getAllUsers().then((response) => {
            setUsersList(response.data.data);
        });
    }, []);

    useEffect(() => {
        if (companyList.length === 0) {
            dispatch(getAllCompaniesAsyncThunk());
        }
    }, [dispatch, companyList.length]);

    const leftToolbarTemplate = () => {
        if (!controlPermission('company.create')) return null;

        return <Button className="text-white mr-4" label="Şirket Ekle" icon="pi pi-plus" severity="success" onClick={() => setCompanyDialog(true)} />;
    };

    const handleModuleDialog = (rowData) => {
        setAttachModuleDialog(true);
        setSelectedCompany(rowData);
    };

    const handleCompanyModuleDialog = async (rowData) => {
        await dispatch(getModulesByCompanyAsyncThunk(rowData.id));
        setSelectedCompany(rowData);
        setSelectedCompanyModuleDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {controlPermission('company.update') && (
                    <Button
                        icon="pi pi-pencil"
                        tooltip="Düzenle"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        onClick={() => {
                            setSelectedCompany(rowData);
                            setEditDialog(true);
                        }}
                    />
                )}
                {controlPermission('company.module.addModule') && (
                    <Button icon="pi pi-tag" tooltip="Bağla" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} className="mx-2 text-white" rounded severity="success" onClick={() => handleModuleDialog(rowData)} />
                )}
                {controlPermission('company.module.getByCompanyModules') && (
                    <Button
                        icon="pi pi-briefcase"
                        tooltip="Bağlı Modüller"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        className="mr-2 border-bluegray-500 bg-bluegray-500 text-white"
                        onClick={() => handleCompanyModuleDialog(rowData)}
                    />
                )}
                <Button
                    icon="pi pi-users"
                    tooltip="Bağlı Kullanıcılar"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    className="mr-2 border-cyan-400 bg-cyan-400 text-white"
                    onClick={() => {
                        setSelectedCompany(rowData);
                        setUserDialogVisible(true);
                    }}
                />
                {controlPermission('company.delete') && (
                    <Button
                        icon="pi pi-trash"
                        tooltip="Sil"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        severity="danger"
                        onClick={() => {
                            setSelectedCompany(rowData);
                            setDeleteCompanyDialog(true);
                        }}
                    />
                )}
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Şirketler</h4>
            <div>
                {leftToolbarTemplate()}
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const addProgram = (rowData) => {
        setAddProgramDialog(true);
        setSelectedModule(rowData);
    };

    const navigateUpdateModule = (rowData) => {
        dispatch(setUpdatedModule(rowData));
        dispatch(setSelectedCompanyAction(selectedCompany));
        const programTitle = rowData.settings.program?.title.split(' ')[0];
        navigate(`/matchmodulesupdate/${rowData.title}/${programTitle}`);
    };

    const detachCompany = async (id) => {
        setLoading(true);
        setUserDialogVisible(false);
        setCompanyDialog(false);
        setDeleteDialogData(null);

        try {
            await detachUser({ companyId: selectedCompany.id, userId: id });
            await dispatch(getAllCompaniesAsyncThunk());
            await dispatch(getAllUsersAsyncThunk());
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirketten kullanıcı bağlantısı kaldırma işlemi başarılı.', life: 2000 });
            }, 20);
        } catch (err) {
            setTimeout(() => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: err.response.data.message, life: 2000 });
            }, 20);
        }
        setLoading(false);
        setSelectedUser(null);
        setSelectedCompany(null);
    };

    const attachCompanyToUser = async () => {
        setLoading(true);
        setUserDialogVisible(false);
        setAttachCompanyDialog(false);

        try {
            await attachUser({ companyId: [selectedCompany.id], userId: selectedUser });
            await dispatch(getAllCompaniesAsyncThunk());
            await dispatch(getAllUsersAsyncThunk());
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirkete kullanıcı bağlama işlemi başarılı.', life: 2000 });
            }, 20);
        } catch (err) {
            setTimeout(() => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: err.response.data.message, life: 2000 });
            }, 20);
        }
        setLoading(false);
        setSelectedUser(null);
        setSelectedCompany(null);
    };

    const actionSubBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {!rowData.settings?.program && <Button className="mr-2" icon="pi pi-plus" tooltip="Ekle" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded severity="primary" onClick={() => addProgram(rowData)} />}
                {rowData.settings?.program && (controlPermission('company.module.setModuleSetting') || controlPermission('company.module.getModuleSetting')) && (
                    <Button icon="pi pi-sync" tooltip="Güncelle" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded severity="success" className="mr-2 text-white" onClick={() => navigateUpdateModule(rowData)} />
                )}
                {controlPermission('company.module.removeModule') && (
                    <Button
                        icon="pi pi-trash"
                        tooltip="Sil"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        severity="danger"
                        onClick={() => {
                            setSelectedModule(rowData);
                            setDeleteModuleDialog(true);
                        }}
                    />
                )}
            </React.Fragment>
        );
    };

    const actionDetachUserTemplate = (rowData) => {
        return (
            <React.Fragment>
                {controlPermission('users.removeCompany') && (
                    <Button
                        icon="pi pi-trash"
                        tooltip="Sil"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        severity="danger"
                        onClick={() => {
                            setDeleteDialogData({
                                visible: true,
                                header: 'Kullanıcının Bağlantısını Kaldır',
                                submitLabel: 'Bağlantıyı Kaldır',
                                submitIcon: 'pi pi-user-minus',
                                content: rowData.name + ' kullanıcısının bağlantısını kaldırmak istediğinize emin misiniz?',
                                onHide: () => setDeleteDialogData(null),
                                onSubmit: () => detachCompany(rowData.id)
                            });
                        }}
                    />
                )}
            </React.Fragment>
        );
    };

    const navigateProgramPage = (program) => {
        dispatch(setSelectedCompanyAction(selectedCompany));
        dispatch(setRequirements({ programReq: program, companyId: selectedCompany.id, module: selectedModule }));
        const programTitle = program?.title.split(' ')[0];
        navigate(`/matchmodules/${selectedModule.title}/${programTitle}`);
    };

    const attachModuleToCompany = async (moduleId) => {
        setAttachModuleDialog(false);
        setSelectedModule(null);
        await dispatch(attachModuleAsyncThunk({ companyId: selectedCompany.id, moduleId: moduleId }));
    };

    const usersHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                <h5 className="m-0">
                    <strong>{selectedCompany?.name}</strong> şirketine bağlı Kullanıcılar
                </h5>
            </div>
        );
    };

    const attachDialogHeaderTemplate = () => {
        return (
            <div className="flex flex-wrap gap-5 align-items-center justify-content-start">
                <Dropdown showClear filter className="w-5" dataKey="id" value={selectedUser} options={usersList} optionLabel="name" optionValue="id" onChange={(e) => setSelectedUser(e.value)} placeholder="Kullanıcı Seç" />
                <Button disabled={!selectedUser} className="text-white mr-4" label="Kullanıcı Bağla" icon="pi pi-plus" severity="success" onClick={() => attachCompanyToUser()} />
            </div>
        );
    };

    if (status === 'loading' || loading) return <Loading />;

    return (
        <div>
            <Toast ref={toast} />
            <div className="card">
                <DataTable
                    ref={dt}
                    value={companyList}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{totalRecords} şirketten {first} ila {last} arası gösteriliyor"
                    globalFilter={globalFilter}
                    header={header}
                    emptyMessage="Şirket Bulunamadı"
                >
                    <Column selectionMode="multiple" exportable={false}></Column>
                    <Column field="name" header="Şirket İsmi" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="tax_number" header="Vergi Numarası" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="tax_office" header="Vergi Dairesi" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="phone" header="Telefon" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="email" header="E-Mail" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="address" header="Adres" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column header="İşlemler" body={actionBodyTemplate} exportable={false} style={{ minWidth: '16rem' }}></Column>
                </DataTable>

                <Dialog header="Şirket Modülleri" visible={selectedCompanyModuleDialog} style={{ width: '40vw' }} modal contentStyle={{ height: '150px' }} onHide={() => setSelectedCompanyModuleDialog(false)}>
                    <DataTable dataKey="id" emptyMessage="Modül Bulunamadı" value={modules || []} className="h-full">
                        <Column field="title" header="Modül Adı" style={{ minWidth: '12rem' }} sortable></Column>
                        <Column field="settings.program.title" header="Program Adı" body={(rowData) => programTemplate(rowData)} style={{ minWidth: '12rem' }} sortable></Column>
                        <Column header="İşlemler" body={actionSubBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    </DataTable>
                </Dialog>

                <Dialog
                    header={usersHeader}
                    visible={userDialogVisible}
                    style={{ width: '40vw' }}
                    modal
                    contentStyle={{ height: 'auto' }}
                    onHide={() => {
                        setUserDialogVisible(false);
                        setSelectedUser(null);
                    }}
                >
                    <DataTable dataKey="id" emptyMessage="Kullanıcı Bulunamadı" value={selectedCompany?.users || []} className="h-full" header={attachDialogHeaderTemplate}>
                        <Column field="name" header="İsim" sortable style={{ minWidth: '12rem' }}></Column>
                        <Column field="email" header="Email" sortable style={{ minWidth: '16rem' }}></Column>
                        <Column header="İşlemler" body={actionDetachUserTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    </DataTable>
                </Dialog>

                <AddProgramDialog visible={addProgramDialog} selectedModule={selectedModule} onHide={() => setAddProgramDialog(false)} onSubmit={navigateProgramPage} />

                <AttachModuleDialog visible={attachModuleDialog} onHide={() => setAttachModuleDialog(false)} onSubmit={attachModuleToCompany} />
                <DeleteModuleDialog
                    visible={deleteModuleDialog}
                    selectedModule={selectedModule}
                    onHide={() => {
                        setDeleteModuleDialog(false);
                        setSelectedModule(null);
                    }}
                />

                <AddDialog show={companyDialog} hideDialog={() => setCompanyDialog(false)} />
                <EditDialog
                    show={editDialog}
                    hideDialog={() => setEditDialog(false)}
                    selectedCompany={selectedCompany}
                    onEditCompany={() => {
                        setEditDialog(false);
                        setSelectedCompany(null);
                    }}
                />
                <DeleteDialog
                    show={deleteCompanyDialog}
                    companyId={selectedCompany?.id}
                    name={selectedCompany?.name}
                    hideDialog={() => setDeleteCompanyDialog(false)}
                    onDeleteCompany={() => {
                        setDeleteCompanyDialog(false);
                        setSelectedCompany(null);
                    }}
                />
            </div>
            <CommonDeleteDialog {...deleteDialogData} />
        </div>
    );
}
