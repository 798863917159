import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { convertReq, convertName } from '../utils/util';
import { updateProgramReq, setProgramReq } from '../../../../store/modules/lucaSlice';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

import { sortReq } from '../utils/util';

export default function ProgramReq({ req = null, title = null, onLogin = () => {}, isLogin = true, loading = false }) {
    const { selectedProgram } = useSelector((state) => state.modules);
    const { programReq } = useSelector((state) => state.luca);
    const [showPassword, setShowPassword] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedProgram && !req) {
            dispatch(setProgramReq(convertReq(selectedProgram?.requirements.split(','))));
        }

        if (req) {
            dispatch(setProgramReq(req));
        }
    }, []);

    const onProgramInputChange = (e, title) => {
        dispatch(updateProgramReq({ title, value: e.target.value }));
    };

    return (
        <div className="w-full">
            <form className="mt-5" onSubmit={onLogin}>
                <h3>{selectedProgram?.title || title}</h3>
                <div className='flex'>
                    {programReq &&
                        sortReq(programReq, ["username", "password", "customerNumber"])?.map((req, i) => {
                            return (
                                <div key={i} className="py-3 flex-1 mr-4">
                                    <div className="w-full mb-2">
                                        <label htmlFor={req}>{convertName(req)}</label>
                                    </div>
                                    <div className="w-full relative">
                                        <InputText type={req === "password" && showPassword ? "password" : "text"} onChange={(e) => onProgramInputChange(e, req)} placeholder={convertName(req)} value={programReq[req]} className="w-full" />
                                        {
                                            req === "password" && (
                                                <Button 
                                                    type="button" 
                                                    className="p-button-text p-button-sm p-0 text-white ml-2 absolute right-0 top-0 h-full"  
                                                    icon={showPassword ? "pi pi-eye" : "pi pi-eye-slash"} 
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    />
                                            )
                                        }
                                    </div>
                                </div>
                            );
                        })}
                </div>
                {!isLogin ? (
                    <Message className="py-3 my-3" severity="warn" text="Şirketleri ve periyotları görüntülemek için giriş yapınız." />
                ) : (
                    <Message className="py-3 my-3" severity="success" text="Şirketleri ve periyotları oluşturabilirsiniz." />
                )}
                <div className="w-full">
                    <Button label="Giriş Yap" icon="pi pi-check" className="p-button-success text-white p-mr-2 mt-3" loading={loading} />
                </div>
            </form>
        </div>
    );
}
