import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';

export default function VideoActionDialog({ selectedVideo, visible, onClose, onSubmit, categoryList = [] }) {
    const [title, setTitle] = useState(selectedVideo?.title || '');
    const [subtitle, setSubtitle] = useState(selectedVideo?.subtitle || '');
    const [url, setUrl] = useState(selectedVideo?.url || '');
    const [selectedCategory, setSelectedCategory] = useState(selectedVideo?.category_id || null);

    useEffect(() => {
        setTitle(selectedVideo?.title || '');
        setSubtitle(selectedVideo?.subtitle || '');
        setUrl(selectedVideo?.url || '');
        let category = categoryList.find((category) => category.id === selectedVideo?.category_id);
        setSelectedCategory(category || null);
    }, [selectedVideo, categoryList]);

    const handleSubmit = () => {
        onSubmit({
            title,
            subtitle,
            url,
            category_id: selectedCategory?.id
        });
        setTitle('');
        setSubtitle('');
        setUrl('');
        setSelectedCategory(null);
    }

    if (!visible) return null

    return (
        <Dialog header="Video İşlemleri" visible={visible} style={{ width: '50vw' }} maximizable modal onHide={() => onClose()}>
            <div>
                {
                    selectedVideo &&
                        <div className="field">
                            <label className="font-bold">Modül Adı</label>
                            <Dropdown
                                dataKey="id"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.value)}
                                options={categoryList}
                                optionLabel="name"
                                placeholder="Kategori Seçiniz"
                                filter
                                className="w-full mr-4 "
                            />
                        </div>
                }
                <div className="w-full mb-2">
                    <label>Başlık</label>
                </div>
                <div className="w-full">
                    <InputText className="w-full mb-4" type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Başlık" required />
                </div>
                <div className="w-full mb-2">
                    <label>Alt Başlık</label>
                </div>
                <div className="w-full">
                    <InputText className="w-full mb-4" type="text" value={subtitle} onChange={(e) => setSubtitle(e.target.value)} placeholder="Alt Başlık" required />
                </div>
                <div className="w-full mb-2">
                    <label>iFrame</label>
                </div>
                <div className="w-full">
                    <InputTextarea rows={10} className="w-full mb-4" type="text" value={url} onChange={(e) => setUrl(e.target.value)} placeholder="iFrame" required />
                </div>
                <div className="w-full mb-2 flex justify-content-end">
                    <Button label="Kaydet" icon="pi pi-check" onClick={() => handleSubmit()} type="submit" disabled={title === "" || subtitle === "" || url === ""} />
                </div>
            </div>
        </Dialog>
    )
}