import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSelector } from 'react-redux';
import { clearRequirements, setModuleSettingsAsyncThunk } from '../../../store/modules/moduleSlice';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import Loading from './components/Loading';

import { ParasutRequest } from './utils/parasutRequest';
import { getCompanyForDropdown, deleteCompanyIsHere } from './utils/util';
import ProgramReq from './components/ProgramReq';

import { getAllWebServicesByModuleId } from '../../../service/request/moduleRequest';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

import { portalFaturaCheck } from './utils/parasutRequest';

export default function UpdateParasut() {
    const { updatedModule, selectedCompany: selectedCompanySlice } = useSelector((state) => state.modules);
    const { programReq } = useSelector((state) => state.parasut);

    const [companyList, setCompanyList] = useState(null);

    const [loading, setLoading] = useState(false);

    const [isLoginParasut, setIsLoginParasut] = useState(false);
    const [loginError, setLoginError] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [parasutCompanies, setParasutCompanies] = useState([]);

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedWebService, setSelectedWebService] = useState(null);
    const [webServicesList, setWebServicesList] = useState(null);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    const [dialogVisible, setDialogVisible] = useState(false);
    const [updateDialogVisible, setUpdateDialogVisible] = useState(false);

    const [updateCompanyDialog, setUpdateCompanyDialog] = useState(false);

    const [pending, setPending] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (updatedModule) {
            setParasutCompanies(updatedModule.settings.program.parasutCompanies);
        }
    }, [updatedModule]);

    useEffect(() => {
        if (loginError) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: loginError, life: 3000 });

            setTimeout(() => {
                setLoginError(null);
            }, 3000);
        }
    }, [loginError]);

    useEffect(() => {
        if (updateCompanyDialog) {
            let company = parasutCompanies.find((item) => item.companyCode === String(selectedCompany));
            setSelectedWebService(company?.webService?._id);
            setUsername(company?.webService?.requirements?.username);
            setPassword(company?.webService?.requirements?.password);
        }
    }, [selectedCompany, parasutCompanies]);

    const getWebServices = async () => {
        const res = await getAllWebServicesByModuleId(updatedModule?.id);
        setWebServicesList(res.data.data);
        setLoading(false);
    };

    useEffect(() => {
        if (updatedModule) {
            getWebServices();
        }
    }, [dispatch]);

    const toast = useRef(null);

    const login = async (e) => {
        e.preventDefault();
        setLoading(true);

        const res = await ParasutRequest.login(programReq.username, programReq.password, programReq.clientId, programReq.clientSecret);

        if (res.name === 'AxiosError') {
            setLoginError('Paraşüt Giriş bilgileriniz hatalıdır.');
        } else {
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Paraşüt Girişi Başarılı.', life: 1500 });
            }, 100);
            setCompanyList(deleteCompanyIsHere(getCompanyForDropdown(res), parasutCompanies));
            setIsLoginParasut(true);
        }
        setLoading(false);
    };

    const save = async () => {
        const data = {
            moduleId: updatedModule.id,
            companyId: updatedModule.pivot.company_id,
            settings: {
                moduleId: updatedModule.id,
                title: updatedModule.title,
                program: {
                    _id: updatedModule.settings.program._id,
                    title: updatedModule.settings.program.title,
                    requirements: [
                        {
                            username: programReq.username,
                            password: programReq.password,
                            clientId: programReq.clientId,
                            clientSecret: programReq.clientSecret
                        }
                    ],
                    parasutCompanies: parasutCompanies
                }
            }
        };

        setPending(true);
        let res = await dispatch(setModuleSettingsAsyncThunk(data));

        if (res.meta.requestStatus === 'fulfilled') {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Modül Eklendi', life: 1500 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Modül Eklenemedi', life: 1500 });
        }

        setTimeout(() => {
            setPending(false);
            navigate(`/companies`);
            dispatch(clearRequirements());
        }, 500);
    };

    const handleCreateCompany = () => {
        const company = companyList.find((item) => item.value === selectedCompany);
        const webServiceRequirements = webServicesList.find((item) => item.id === selectedWebService);

        const data = {
            name: company.title,
            id: company.value,
            webService: {
                _id: webServiceRequirements.id,
                title: webServiceRequirements.title,
                url: webServiceRequirements.url,
                requirements: {
                    username: username,
                    password: password
                }
            }
        };

        setParasutCompanies([...parasutCompanies, data]);
        setCompanyList(companyList.filter((item) => item.value !== selectedCompany));

        setTimeout(() => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Eklendi', life: 1500 });
        }, 100);

        setUpdateCompanyDialog(false);
        setSelectedWebService(null);
        setUsername('');
        setPassword('');
        setSelectedCompany(null);
        setDialogVisible(false);
    };

    const handleCheckPortal = async () => {
        const webService = webServicesList.find((item) => item.id === selectedWebService);

        const data = {
            url: webService.url,
            username: username,
            password: password
        };

        const res = await portalFaturaCheck(data);

        if (res.data.success) {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Web servis kullanıcı bilgileriniz doğrudur', life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Web servis kullanıcı bilgileriniz hatalıdır', life: 3000 });
        }
    };

    const handleDeleteCompany = (rowData) => {
        setParasutCompanies(parasutCompanies.filter((item) => item.id !== rowData.id));

        setCompanyList([...companyList, { title: rowData.name, value: rowData.id }]);

        setTimeout(() => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Silindi', life: 1500 });
        }, 100);
    };

    const handleUpdateCompanyDialog = (rowData) => {
        setUpdateDialogVisible(true);
        setSelectedWebService(rowData.webService._id);
        setUsername(rowData.webService.requirements.username);
        setPassword(rowData.webService.requirements.password);
        setSelectedCompany(rowData);
    };

    const handleUpdateCompany = () => {
        setUpdateDialogVisible(false);
        const webServiceRequirements = webServicesList.find((item) => item.id === selectedWebService);
        const data = {
            name: selectedCompany.name,
            id: selectedCompany.id,
            webService: {
                _id: webServiceRequirements.id,
                title: webServiceRequirements.title,
                url: webServiceRequirements.url,
                requirements: {
                    username: username,
                    password: password
                }
            }
        };
        let copy = [...parasutCompanies];
        const index = copy.findIndex((item) => item.id === selectedCompany.id);
        copy[index] = data;

        setParasutCompanies([...copy]);
        setCompanyList(companyList.filter((item) => item.value !== selectedCompany.id));

        setTimeout(() => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Güncellendi', life: 1500 });
        }, 100);

        setUpdateCompanyDialog(false);
        setSelectedWebService(null);
        setUsername('');
        setPassword('');
        setSelectedCompany(null);
        setDialogVisible(false);
    };

    const actionCodeTemplate = (rowData) => {
        return (
            <>
                <Button className="mr-2" icon="pi pi-pencil" tooltip="Düzenle" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded outlined severity="primary" onClick={() => handleUpdateCompanyDialog(rowData)} />
                <Button icon="pi pi-trash" tooltip="Sil" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded outlined severity="danger" onClick={() => handleDeleteCompany(rowData)} />
            </>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Şirketler</h4>
            <div>
                <Button label="Şirket Ekle" icon="pi pi-plus-circle" className="p-button-primary text-white mr-4 mb-0" onClick={() => setDialogVisible(true)} disabled={!isLoginParasut} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const webServiceReqTemplate = () => {
        setDialogVisible(false);
        setUpdateDialogVisible(false);
        setUsername('');
        setPassword('');
        setSelectedWebService(null);
        setSelectedCompany(null);
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const checkCreate = () => {
        return selectedCompany && username && password && selectedWebService;
    };

    const dialogFooterTemplate = () => {
        return <Button label="Oluştur" icon="pi pi-check" className="p-button-info mt-3" onClick={() => handleCreateCompany()} disabled={!checkCreate()} />;
    };
    const updateDialogFooterTemplate = () => {
        return <Button label="Güncelle" icon="pi pi-check" className="p-button-info mt-3" onClick={() => handleUpdateCompany()} disabled={!checkCreate()} />;
    };

    const changeWebServices = (e) => {
        setUsername('');
        setPassword('');
        setSelectedWebService(e.value);
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="flex align-items-center">
                <Button className="" icon="pi pi-building" />
                <h3 className="mb-4 ml-3">{selectedCompanySlice?.name}</h3>
            </div>
            <div className="card">
                <div>
                    <div className="flex flex-column md:flex-row">
                        <div className="w-full">
                            <ProgramReq onLogin={login} isLogin={isLoginParasut} req={updatedModule?.settings.program.requirements[0]} title={updatedModule?.settings.program.title} />
                        </div>
                    </div>
                </div>

                <Dialog header="Şirket Ekle" visible={dialogVisible} style={{ width: '50vw' }} maximizable modal onHide={() => webServiceReqTemplate()} footer={dialogFooterTemplate}>
                    <div className="mt-4">
                        {isLoginParasut && (
                            <>
                                <label className="mb-2 block">Şirketler</label>
                                <Dropdown
                                    dataKey="_id"
                                    value={selectedCompany}
                                    onChange={(e) => setSelectedCompany(e.value)}
                                    options={companyList}
                                    optionLabel="title"
                                    placeholder="Şirket Seçiniz"
                                    filter
                                    className="w-full mb-4"
                                    emptyMessage="Şirket Bulunamadı"
                                />
                            </>
                        )}

                        <div>
                            <div className="w-full mb-2">
                                <label>Web Servis Programları</label>
                            </div>
                            <div>
                                <Dropdown
                                    dataKey="id"
                                    value={selectedWebService}
                                    onChange={(e) => changeWebServices(e)}
                                    options={webServicesList?.map((item) => ({ value: item.id, label: item.title })) || []}
                                    optionLabel="label"
                                    placeholder="Web Servis Seçiniz"
                                    filter
                                    className="w-full mb-3"
                                    emptyMessage="Web Servis Bulunamadı"
                                />
                            </div>
                        </div>
                        {selectedWebService && (
                            <div className="my-2">
                                <div className="w-full mb-2">
                                    <label>Web Servis Kullanıcı Adı</label>
                                </div>
                                <div className="w-full">
                                    <InputText className="w-full mb-4" type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Kullanıcı Adı" required />
                                </div>
                                <div className="w-full mb-2">
                                    <label>Web Servis Şifre</label>
                                </div>
                                <div className="flex w-full align-items-center gap-3">
                                    <InputText className="w-full" type="text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Şifre" required />
                                    <Button label="Şifreyi Kontrol Et" className="flex-shrink-0" onClick={handleCheckPortal} />
                                </div>
                            </div>
                        )}
                    </div>
                </Dialog>

                <Dialog header="Şirket Güncelle" visible={updateDialogVisible} style={{ width: '50vw' }} maximizable modal onHide={() => webServiceReqTemplate()} footer={updateDialogFooterTemplate}>
                    <div className="mt-4">
                        <div>
                            <div className="w-full mb-2">
                                <label>Web Servis Programları</label>
                            </div>
                            <div>
                                <Dropdown
                                    dataKey="id"
                                    value={selectedWebService}
                                    onChange={(e) => changeWebServices(e)}
                                    options={webServicesList?.map((item) => ({ value: item.id, label: item.title })) || []}
                                    optionLabel="label"
                                    placeholder="Web Servis Seçiniz"
                                    filter
                                    className="w-full mb-3"
                                    emptyMessage="Web Servis Bulunamadı"
                                />
                            </div>
                        </div>
                        {selectedWebService && (
                            <div className="my-2">
                                <div className="w-full mb-2">
                                    <label>Web Servis Kullanıcı Adı</label>
                                </div>
                                <div className="w-full">
                                    <InputText className="w-full mb-4" type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Kullanıcı Adı" required />
                                </div>
                                <div className="w-full mb-2">
                                    <label>Web Servis Şifre</label>
                                </div>
                                <div className="flex w-full align-items-center gap-3">
                                    <InputText className="w-full" type="text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Şifre" required />
                                    <Button label="Şifreyi Kontrol Et" className="flex-shrink-0" onClick={handleCheckPortal} />
                                </div>
                            </div>
                        )}
                    </div>
                </Dialog>
                {parasutCompanies && isLoginParasut && (
                    <DataTable
                        value={parasutCompanies}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{totalRecords} şirketten {first} ila {last} arası gösteriliyor"
                        emptyMessage="Şirket Bulunamadı"
                        className="my-6"
                        header={header}
                        globalFilter={globalFilter}
                    >
                        <Column field="id" header="Şirket İsmi" sortable style={{ minWidth: '12rem' }}></Column>
                        <Column field="name" header="Periyot" sortable style={{ minWidth: '16rem' }}></Column>
                        <Column field="webService.title" header="Web Servis" sortable style={{ minWidth: '16rem' }}></Column>
                        <Column header="İşlemler" body={actionCodeTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    </DataTable>
                )}

                {isLoginParasut && (
                    <div className="text-center mt-5">
                        <Button label="Güncelle" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => save()} loading={pending} />
                    </div>
                )}
            </div>
        </>
    );
}
