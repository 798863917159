import axios from 'axios';

let BASE_URL = process.env.REACT_APP_INT_URL;

const parasutReq = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

async function loginParasut(username, password, clientId, clientSecret) {
    return await parasutReq.post(`${BASE_URL}/parasut/login`, {
        clientId,
        clientSecret,
        username,
        password
    });
}

async function getCompanies(bearerToken) {
    return await parasutReq.post(`${BASE_URL}/parasut/getCompanies`, { bearerToken });
}

async function portalFaturaCheck(data) {
    return await parasutReq.post(`${BASE_URL}/portal/check`, data);
}

class ParasutRequest {
    static async login(username, password, clientId, clientSecret) {
        try {
            const response = await parasutReq.post(`${BASE_URL}/parasut/login`, {
                clientId,
                clientSecret,
                username,
                password
            });

            this.bearerToken = response.data.data.bearerToken;

            const companies = await this.getCompanies(response.data.data.access_token);

            return companies;
        } catch (error) {
            return error;
        }
    }

    static async getCompanies(bearerToken) {
        try {
            const response = await parasutReq.post(`${BASE_URL}/parasut/getCompanies`, { bearerToken });
            return response.data.data;
        } catch (error) {
            return error;
        }
    }
}

export { loginParasut, getCompanies, ParasutRequest, portalFaturaCheck };
