import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllModules, getAllCommercialProgramsByModuleId, getAllWebServicesByModuleId, addLuca, updateLuca, deleteModule, addParasut, updateParasut, addRapid, updateRapid, addDatasoft, updateDatasoft } from '../../service/request/moduleRequest';
import { setCompanySettings } from '../../service/request/companyRequest';

export const getAllModulesAsyncThunk = createAsyncThunk('modules/getAllModules', async () => {
    const response = await getAllModules();
    return response.data;
});

export const getAllCommercialProgramsByModuleIdAsyncThunk = createAsyncThunk('modules/getAllCommercialProgramsByModuleId', async (id) => {
    const response = await getAllCommercialProgramsByModuleId(id);
    return response.data;
});

export const getAllWebServicesByModuleIdAsyncThunk = createAsyncThunk('modules/getAllWebServicesByModuleId', async (id) => {
    const response = await getAllWebServicesByModuleId(id);
    return response.data;
});

export const addLucaAsyncThunk = createAsyncThunk('modules/addLuca', async (data) => {
    const response = await addLuca(data);
    return response.data;
});

export const updateLucaAsyncThunk = createAsyncThunk('modules/updateLuca', async (data) => {
    const response = await updateLuca(data);
    return response.data;
});

export const addDatasoftAsyncThunk = createAsyncThunk('modules/addDatasoft', async (data) => {
    const response = await addDatasoft(data);
    return response.data;
});

export const updateDatasoftAsyncThunk = createAsyncThunk('modules/updateDatasoft', async (data) => {
    const response = await updateDatasoft(data);
    return response.data;
});

export const addParasutAsyncThunk = createAsyncThunk('modules/addParasut', async (data) => {
    const response = await addParasut(data);
    return response.data;
});

export const updateParasutAsyncThunk = createAsyncThunk('modules/updateParasut', async (data) => {
    const response = await updateParasut(data);
    return response.data;
});

export const deleteModuleAsyncThunk = createAsyncThunk('modules/deleteModule', async (data) => {
    const response = await deleteModule(data);
    return data;
});

export const setModuleSettingsAsyncThunk = createAsyncThunk('modules/setModuleSettings', async (data) => {
    const response = await setCompanySettings(data);
    return response.data;
});

export const moduleSlice = createSlice({
    name: 'modules',
    initialState: {
        error: null,
        status: null,
        toastMessage: null,

        moduleList: [],
        module: null,

        selectedModule: null,

        selectedProgram: null,
        selectedCompanyId: null,

        selectedModuleId: null,
        selectedCompany: null,

        updatedModule: null,

        webServicesList: [],
        commercialProgramsList: []
    },
    reducers: {
        setRequirements: (state, action) => {
            state.selectedProgram = action.payload.programReq;
            state.module = action.payload.module;
            state.selectedCompanyId = action.payload.companyId;
        },
        setSelectedCompanyId: (state, action) => {
            state.selectedCompanyId = action.payload;
        },
        setSelectedModuleId: (state, action) => {
            state.selectedModuleId = action.payload;
        },
        setUpdatedModule: (state, action) => {
            state.updatedModule = action.payload;
        },
        setProgramAndCompanyId: (state, action) => {
            state.selectedProgram = action.payload.program;
            state.selectedCompanyId = action.payload.companyId;
        },
        setSelectedModule: (state, action) => {
            state.selectedModule = action.payload;
        },
        clearRequirements: (state) => {
            state.selectedCompanyId = null;
            state.selectedModuleId = null;
            state.selectedProgram = null;
            state.selectedModule = null;
            state.module = null;
        },
        setSelectedCompanyAction: (state, action) => {
            state.selectedCompany = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllModulesAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllModulesAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = action.payload.data;
            })
            .addCase(getAllModulesAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getAllCommercialProgramsByModuleIdAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.commercialProgramsList = action.payload.data;
            })
            .addCase(getAllWebServicesByModuleIdAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getAllWebServicesByModuleIdAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.webServicesList = action.payload.data;
            })
            .addCase(getAllCommercialProgramsByModuleIdAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(addLucaAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = [...state.moduleList, action.payload.data];
            })
            .addCase(addLucaAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateLucaAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = state.moduleList.map((module) => (module._id === action.payload.data._id ? action.payload.data : module));
            })
            .addCase(updateLucaAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(addDatasoftAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = [...state.moduleList, action.payload.data];
            })
            .addCase(addDatasoftAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateDatasoftAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = state.moduleList.map((module) => (module._id === action.payload.data._id ? action.payload.data : module));
            })
            .addCase(updateDatasoftAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(addParasutAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = [...state.moduleList, action.payload.data];
            })
            .addCase(addParasutAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateParasutAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = state.moduleList.map((module) => (module._id === action.payload.data._id ? action.payload.data : module));
            })
            .addCase(updateParasutAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(deleteModuleAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
            })
            .addCase(deleteModuleAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(setModuleSettingsAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(setModuleSettingsAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = state.moduleList.map((module) => (module.id === action.payload.data.id ? action.payload.data : module));
            })
            .addCase(setModuleSettingsAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            });
    }
});

export const {
    setRequirements,
    setSelectedCompanyId,
    setSelectedModuleId,
    setUpdatedModule,
    setProgramAndCompanyId,
    setSelectedModule,
    clearRequirements,
    setSelectedCompanyAction
} = moduleSlice.actions;

export default moduleSlice.reducer;
