export const convertName = (req) => {
    switch (req) {
        case 'username':
            return 'Kullanıcı Adı';
        case 'password':
            return 'Şifre';
        case 'taxNumber':
            return 'Vergi Numarası';
        case 'eDefterSubeKodu':
            return 'E-Defter Şube Kodu';
        case 'eDefterSubeAdi':
            return 'E-Defter Şube Adı';
        default:
            return req;
    }
};

export const convertReq = (req) => {
    let copy = {};
    req.forEach((req) => {
        copy[req] = '';
    });

    return copy
}

export const getPeriodForDropdown = (periodList, companyValue) => {
    let period = [];
    periodList.forEach((item) => {
        if (item.company.value === companyValue) {
            item.periods.forEach((p) => {
                period.push({
                    title: p.label,
                    value: p.value
                });
            });
        }
    });
    return period;
}

export const getCompanyForDropdown = (data) => {
    let company = [];
    data.forEach((item) => {
        company.push({
            title: item.company.label,
            value: item.company.value
        });
    });
    return company;
}