import axios from "axios";
import { roles } from "../url";

export const getAllRoles = async () => {
    return await axios.get(roles.getAllRoles);
}
    
export const createRole = async (role) => {
    return await axios.post(roles.createRole, role);
};

export const updateRole = async (role) => {
    return await axios.put(roles.updateRole, role);
};

export const deleteRole = async (id) => {
    return await axios.delete(roles.deleteRole + `?id=${id}`);
};

export const getPermissions = async (id) => {
    return await axios.get(roles.getPermissions + `?id=${id}`);
};

export const setPermissions = async (data) => {
    return await axios.post(roles.setPermissions, data);
};

export const getAllPermissions = async () => {
    return await axios.get(roles.getAllPermissions);
};

export const getById = async (id) => {
    return await axios.get(roles.getById + `?id=${id}`);
};

    

