import { Button } from 'primereact/button';

import { useEffect, useState, useRef } from 'react';
import { Tag } from 'primereact/tag';
import { DataView } from 'primereact/dataview';
import { getAllTickets, updateStatus } from '../../service/request/ticketsRequest';
import { Dialog } from 'primereact/dialog';

import { Toast } from 'primereact/toast';

import Loading from '../module/datasoft/components/Loading';

import Chat from './ChatDialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

export default function SelectModule() {
    const [tickets, setTickets] = useState(null);
    const [chatVisible, setChatVisible] = useState(false);
    const [addTicketDialog, setAddTicketDialog] = useState(false);
    const [selectedTicketId, setSelectedTicketId] = useState(null);
    const [closeDialog, setCloseDialog] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [loading, setLoading] = useState(true);
    const toast = useRef(null);

    const getAllTicketsAsync = async () => {
        const response = await getAllTickets();
        setTickets(response.data.data);

        setLoading(false);
    };

    useEffect(() => {
        getAllTicketsAsync();
    }, []);

    const getSeverity = (status, unread) => {
        switch (status) {
            case 'open':
                return (
                    <div className="flex justify-content-center">
                        <h6 className="m-0 p-badge p-overlay-badge p-badge-success text-white">
                            Açık <span className="p-badge p-badge-danger">{unread}</span>
                        </h6>
                    </div>
                );

            case 'closed':
                return (
                    <div className="flex justify-content-center">
                        <h6 className="m-0 p-badge p-overlay-badge p-badge-danger text-white">
                            Kapalı <span className="p-badge p-badge-danger">{unread}</span>
                        </h6>
                    </div>
                );

            case 'pending':
                return (
                    <div className="flex justify-content-center">
                        <h6 className="m-0 p-badge p-overlay-badge p-badge-info text-white">
                            Beklemede <span className="p-badge p-badge-danger">{unread}</span>
                        </h6>
                    </div>
                );

            case 'in_progress':
                return (
                    <div className="flex justify-content-center">
                        <h6 className="m-0 p-badge p-overlay-badge p-badge-warning text-white">
                            İşlemde <span className="p-badge p-badge-danger">{unread}</span>
                        </h6>
                    </div>
                );

            default:
                return null;
        }
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className="flex align-items-center gap-5">
                <h2 className="mb-3">Destek Talepleri</h2>
            </div>
            <div className="flex align-items-center">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const formatDate = (inputDateTime) => {
        const dateTimeObj = new Date(inputDateTime);

        const formattedDateTime = dateTimeObj.toLocaleString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        return formattedDateTime;
    };

    const closeTicket = async () => {
        setCloseDialog(false);
        try {
            await updateStatus({ id: selectedTicket.id, status: 'closed' });
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Destek talebi başarıyla kapatıldı.' });
            await getAllTicketsAsync();
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Destek talebi kapatılırken bir hata oluştu.' });
        }
    };

    const itemTemplate = (product) => {
        return (
            <div>
                <Button
                    icon="pi pi-comments"
                    className="p-button-rounded mr-2"
                    tooltip="Mesajlar"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    onClick={() => {
                        setChatVisible(true);
                        setSelectedTicketId(product.id);
                        setSelectedTicket(product);
                    }}
                ></Button>
                {product.status !== 'closed' && (
                    <Button
                        icon="pi pi-times"
                        className="p-button-rounded"
                        tooltip="Talebi Kapat"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        severity="danger"
                        onClick={() => {
                            setCloseDialog(true);
                            setSelectedTicket(product);
                        }}
                    ></Button>
                )}
            </div>
        );
    };

    const substringDescription = (description) => {
        if (description.length > 50) {
            const substring = description.substring(0, 50);
            return substring + '...';
        } else {
            return description;
        }
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const closeTicketFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" outlined onClick={() => setCloseDialog(false)} />
            <Button label="Evet" icon="pi pi-check" severity="danger" onClick={() => closeTicket()} />
        </>
    );

    return (
        <div className="card">
            <Toast ref={toast} />
            <DataTable
                value={tickets}
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 100, 200]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{totalRecords} destek talebinden {first} ila {last} arası gösteriliyor"
                globalFilter={globalFilter}
                header={header}
                emptyMessage="Destek Talebi Bulunamadı"
                className="p-datatable-gridlines"
            >
                <Column field="module.title" header="Modül" sortable style={{ maxWidth: '8rem' }}></Column>
                <Column field="title" header="Başlık" sortable style={{ minWidth: '10rem' }}></Column>
                <Column field="description" header="Açıklama" sortable style={{ minWidth: '20rem' }} body={(rowData) => substringDescription(rowData.description)}></Column>
                <Column field="company.name" header="Şirket Adı" sortable style={{ minWidth: '6rem' }}></Column>
                <Column field="user.name" header="Kullanıcı" sortable style={{ minWidth: '6rem' }}></Column>
                <Column field="user.email" header="Kullanıcı E-Mail" sortable style={{ maxWidth: '10rem' }}></Column>
                <Column field="created_at" header="Oluşturulma Tarihi" sortable style={{ maxWidth: '8rem' }} body={(rowData) => formatDate(rowData.created_at, 'dot')}></Column>
                <Column field="status" header="Durumu / Okunmamış Mesajlar" sortable style={{ maxWidth: '6rem' }} body={(rowData) => getSeverity(rowData.status, rowData.unread)}></Column>
                <Column header="İşlemler" body={itemTemplate} className="text-center" exportable={false} style={{ minWidth: '5rem' }}></Column>
            </DataTable>
            <Chat visible={chatVisible} onHide={() => setChatVisible(false)} ticketId={selectedTicketId} selectedTicket={selectedTicket} />
            {addTicketDialog && (
                <>
                    <Dialog
                        visible={addTicketDialog}
                        onHide={() => setAddTicketDialog(false)}
                        style={{
                            width: '36vw'
                        }}
                        header="Yeni Destek Talebi Oluştur"
                        footer={
                            <div>
                                <Button label="Gönder" icon="pi pi-check" className="p-button-success p-button-rounded text-white" onClick={() => console.log()} />
                            </div>
                        }
                    >
                        <div></div>
                    </Dialog>
                </>
            )}
            <Dialog visible={closeDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Talebi Kapatma" modal footer={closeTicketFooter} onHide={() => setCloseDialog(false)}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>
                        <b>{selectedTicket?.title}</b> adlı destek talebini kapatmak istediğinize emin misiniz ?
                    </span>
                </div>
            </Dialog>
        </div>
    );
}
