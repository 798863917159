import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { removeToken, removeUser, setToken, setUser } from "../../service/tokenServices";
import { login, logout } from '../../service/request/authRequest';
import { getAllUsers } from '../../service/request/userRequest';


export const loginAsyncThunk = createAsyncThunk("auth/login", async ({ email, password }) => {
    try {
        const response = await login(email, password);
        if (response.data.success) {
            setToken(response.data.data.token);
            const userResponse = await getAllUsers();
            return response.data;
        } else {
            return Promise.reject(response.data.error);
        }
    } catch (error) {
        return Promise.reject(error);
    }
});

export const logoutAsyncThunk = createAsyncThunk("auth/logout", async () => {
    const response = await logout();
    return response.data;
});

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        isAuthenticated: false,
        errorMessage: null
    },
    reducers: {
        setStoreUser: (state, action) => {
            state.user = action.payload;
        },
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        clearErrorMessage: (state, action) => {
            state.errorMessage = null;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(loginAsyncThunk.fulfilled, (state, action) => {
                state.user = action.payload.data.user;
                state.isAuthenticated = true;
                setUser(action.payload.data.user);
            })
            .addCase(loginAsyncThunk.rejected, (state, action) => {
                state.user = null;
                state.isAuthenticated = false;
                removeToken();
                removeUser();
                state.errorMessage = "Birşeyler yanlış gitti. Tekrar deneyiniz."
            })
            .addCase(logoutAsyncThunk.fulfilled, (state, action) => {
                state.user = null;
                state.isAuthenticated = false;
                removeToken();
                removeUser();
            });
    }
});

export const { setStoreUser, setIsAuthenticated } = authSlice.actions;
export default authSlice.reducer;