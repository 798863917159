import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteModuleAsyncThunk } from '../../../store/company/companySlice';

export default function DeleteModuleDialog({ visible, selectedModule, onHide }) {
    const [pending, setPending] = useState(false);

    const dispatch = useDispatch();

    const deleteModule = async (rowData) => {
        setPending(true);
        const res = await dispatch(deleteModuleAsyncThunk(selectedModule.pivot));
        setPending(false);
        onHide();
    };

    const deleteCompanyDialogFooter = (
        <React.Fragment>
            <Button label="Hayır" icon="pi pi-times" outlined onClick={() => onHide()} />
            <Button label="Evet" icon="pi pi-check" severity="danger" onClick={() => deleteModule()} loading={pending} />
        </React.Fragment>
    );

    return (
        <Dialog visible={visible} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Silme" modal footer={deleteCompanyDialogFooter} onHide={() => onHide()}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                <span>
                    <b>{selectedModule?.title}</b> Silmek istediğinize emin misiniz ?
                </span>
            </div>
        </Dialog>
    );
}
