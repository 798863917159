import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { filterCommercialPrograms } from '../utils/utils';
import { getAllCommercialProgramsByModuleIdAsyncThunk } from '../../../store/modules/moduleSlice';


export default function AddProgramDialog({ visible, selectedModule, onHide, onSubmit }) {
    const { commercialProgramsList } = useSelector((state) => state.modules);
    const { modules } = useSelector((state) => state.company);

    const [selectedProgram, setSelectedProgram] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (visible) {
            req();
        }
    }, [dispatch, visible]);

    const req = async () => {
        await dispatch(getAllCommercialProgramsByModuleIdAsyncThunk(selectedModule?.id));
    };

    const handleSubmit = () => {
        const program = commercialProgramsList.find((program) => program.id === selectedProgram);
        onSubmit(program);
    }

    const attachDialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" outlined onClick={() => onHide()} />
            <Button label="Bağla" icon="pi pi-user-plus" onClick={() => handleSubmit()} type="submit" disabled={!selectedProgram} />
        </React.Fragment>
    );

    if (!visible) {
        return null;
    }

    return (
        <Dialog
            visible={visible}
            style={{ width: '32rem' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Program Ekle"
            modal
            className="p-fluid"
            footer={attachDialogFooter}
            onHide={() => onHide()}
        >
            <div className="field">
                <label htmlFor="Program Listesi" className="font-bold">
                    Program Listesi
                </label>
                <Dropdown
                    emptyFilterMessage="Program bulunamadı"
                    emptyMessage="Program bulunamadı"
                    id="Program Listesi"
                    filter
                    value={selectedProgram}
                    options={filterCommercialPrograms(commercialProgramsList, modules, selectedModule)}
                    onChange={(e) => setSelectedProgram(e.value)}
                    placeholder="Program Listesi"
                />
            </div>
        </Dialog>
    )
}