import { modules } from '../url';
import axios from 'axios';

export const getAllModules = async () => {
    return await axios.get(modules.getAllModules);
}

export const getAllCommercialProgramsByModuleId = async (id) => {
    return await axios.get(modules.getAllCommercialProgramsByModuleId + `?moduleId=${id}`);
}

export const getAllWebServicesByModuleId = async (id) => {
    return await axios.get(modules.getAllWebServicesByModuleId + `?moduleId=${id}`);
}

export const addLuca = async (data) => {
    return await axios.post(modules.addLuca, data);
}

export const updateLuca = async (data) => {
    return await axios.put(modules.updateLuca, data);
}

export const addParasut = async (data) => {
    return await axios.post(modules.addParasut, data);
}

export const updateParasut = async (data) => {
    return await axios.put(modules.updateParasut, data);
}

export const addRapid = async (data) => {
    return await axios.post(modules.addRapid, data);
}

export const updateRapid = async (data) => {
    return await axios.put(modules.updateRapid, data);
}

export const deleteModule = async (data) => {
    return await axios.delete(modules.deleteModule + `?moduleId=${data.module_id}&companyId=${data.company_id}`);
}

export const addDatasoft = async (data) => {
    return await axios.post(modules.addDatasoft, data);
}

export const updateDatasoft = async (data) => {
    return await axios.put(modules.updateDatasoft, data);
}

