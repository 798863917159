import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

export default function ConfirmDialog({ dialogTitle, text, visible, onClose, onConfirm }) {
    if (!visible) return null;

    const deleteDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" outlined onClick={onClose} />
            <Button label="Evet" icon="pi pi-check" severity="danger" onClick={onConfirm} />
        </>
    );

    return (
        <Dialog header={dialogTitle} visible={visible} style={{ width: '36vw' }} modal onHide={() => onClose()} footer={deleteDialogFooter}>
            <div className="">
                <div className="text-lg">
                    <span className="font-bold">{text.title}</span>
                    <span>{text.content}</span>
                </div>
            </div>
        </Dialog>
    )
}