import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DocumentCode from '../../../../assets/data/DocumentCode.json';
import { addNewCode, deleteCode } from '../../../../store/modules/lucaSlice';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

/*

Luca Belge Kodları
Kullanıcının kendi şirketinde kullanacağı belge kodlarını tanımlayabileceği sayfa

*/

export default function LucaDocCode() {
    const { codeList } = useSelector((state) => state.luca);

    const [selectedCode, setSelectedCode] = useState(null);
    const [newCode, setNewCode] = useState('');
    const [dialogVisible, setDialogVisible] = useState(false);

    const dispatch = useDispatch();
    const toast = useRef(null);

    const handleAddCode = () => {
        dispatch(addNewCode({ code: selectedCode, enum: newCode }));
        setNewCode('');
        setSelectedCode(null);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Kod Eklendi', life: 3000 });
    };

    const handleDeleteCode = (code) => {
        dispatch(deleteCode(code));
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Kod Silindi', life: 3000 });
    };

    const actionCodeTemplate = (rowData) => {
        return <Button icon="pi pi-trash" tooltip="Sil" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} className="mx-2" rounded outlined severity="danger" onClick={() => handleDeleteCode(rowData)} />;
    };

    const dialogFooterTemplate = () => {
        return <Button label="Tamam" icon="pi pi-check" onClick={() => setDialogVisible(false)} />;
    };

    const getCodeList = () => {
        let list = [];
        DocumentCode.forEach((item) => {
            let code = codeList?.find((code) => code.code === item.code);
            if (!code) {
                list.push(item);
            }
        });

        return list;
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="">
                <div className="flex justify-content-between align-items-end relative">
                    <div className="w-5">
                        <label className="block mb-4 relative" style={{ bottom: '-30px' }}>
                            Varsayılan Belge Kodu
                        </label>
                        <Dropdown
                            dataKey="id"
                            value={selectedCode}
                            onChange={(e) => setSelectedCode(e.value)}
                            options={DocumentCode.map((item) => ({ label: `${item.code} - ${item.name}`, value: item.code }))}
                            optionLabel="label"
                            placeholder="Varsayılan Belge Kodu Seçiniz"
                            className="w-full"
                            emptyMessage="Kod Bulunamadı"
                            style={{ top: '22px' }}
                        />
                    </div>
                    <div className="w-4">
                        <label className="block relative" style={{ bottom: '-10px' }}>
                            Yeni Belge Kodu
                        </label>
                        <InputText type="text" style={{ top: '22px' }} className="relative w-full" value={newCode} onChange={(e) => setNewCode(e.target.value)} placeholder="Yeni Belge Kodu" />
                    </div>
                    <Button label="Ekle" icon="pi pi-check" className="p-button-success text-white px-3 relative" style={{ top: '22px' }} onClick={() => handleAddCode()} disabled={!selectedCode || !newCode} />
                    <Button label="Göster" icon="pi pi-external-link px-1" className="relative" style={{ top: '22px' }} onClick={() => setDialogVisible(true)} />
                </div>
            </div>
            <div>
                <Dialog header="Yeni Kodlar" visible={dialogVisible} style={{ width: '50vw' }} maximizable modal contentStyle={{ height: '300px' }} onHide={() => setDialogVisible(false)} footer={dialogFooterTemplate}>
                    <DataTable value={codeList} scrollable scrollHeight="flex" tableStyle={{ minWidth: '50rem' }} emptyMessage="Kod Bulunamadı">
                        <Column field="code" header="Varsayılan Belge Kodu"></Column>
                        <Column field="enum" header="Yeni Belge Kodu"></Column>
                        <Column header="İşlemler" body={actionCodeTemplate} exportable={false}></Column>
                    </DataTable>
                </Dialog>
            </div>
        </div>
    );
}
