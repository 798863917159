import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import { getTicketById, sendMessage } from '../../service/request/ticketsRequest';
import Loading from '../module/datasoft/components/Loading';

const Chat = ({ visible, onHide, ticketId, selectedTicket }) => {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const lastMessageRef = useRef(null);

    function formatDateTime(dateTimeString) {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        };

        const formattedDateTime = new Date(dateTimeString).toLocaleString('tr-TR', options);
        return formattedDateTime;
    }

    useEffect(() => {
        if (visible && messages.length === 0) {
            setLoading(true);
            getTicketById(ticketId).then((response) => {
                setMessages(response.data.data);
                setLoading(false);
            });
        }
    }, [visible]);

    useEffect(() => {
        let intervalId;

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await getTicketById(ticketId);
                setMessages(response.data.data);
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };

        if (visible) {
            intervalId = setInterval(fetchData, 10000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [visible, ticketId]);

    const handleHide = () => {
        onHide();
        setMessages([]);
    };

    const sendMessageAsync = async () => {
        const response = await sendMessage({ id: ticketId, message });
        if (response.status === 200) {
            setMessage('');
            getTicketById(ticketId).then((response) => {
                setMessages(response.data.data);
            });
        }
    };

    const scrollToLastMessage = () => {
        lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <Dialog
            visible={visible}
            onHide={() => handleHide()}
            style={{ width: '48vw' }}
            maximizable
            closeIcon="pi pi-times text-primary"
            header={
                <div className="flex justify-between">
                    <h3 className="text-xl font-semibold">Sohbet</h3>
                </div>
            }
            footer={
                <div className="w-full pt-5 relative">
                    <div className="flex">
                        <InputText
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Bir mesaj yazın"
                            className="mr-4 w-full py-3"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && message.trim()) {
                                    sendMessageAsync();
                                }
                            }}
                        />
                        <Button icon="pi pi-send" onClick={() => sendMessageAsync()} disabled={!message.trim()} className="px-6" />
                        { messages?.messages?.length > 0 && <Button className="w-3rem h-3rem absolute" iconPos="right" style={{ bottom: '6rem', right: '0' }} rounded icon="pi pi-chevron-down" onClick={scrollToLastMessage} />}
                    </div>
                </div>
            }
        >
            {loading ? (
                <Loading loading={loading} onChangeLoading={() => setLoading(false)} />
            ) : (
                <div className="p-grid p-justify-center">
                        <div className="p-col-12 p-md-8 p-lg-6">
                            <div className='mb-4 card'>
                                <label className='mb-3 block'>Açıklama</label>
                            <p className='card'>{selectedTicket?.description}</p>
                            </div>
                        {messages?.messages?.length > 0 ? (
                            <div className="card">
                                <div className="flex flex-column">
                                    {messages?.messages.map((msg, index) => (
                                        <div key={index} className={`flex mb-4 ${msg.user_id === messages.user_id ? '' : 'justify-content-end text-right'}`} ref={index === messages.messages.length - 1 ? lastMessageRef : null}>
                                            <div
                                                className={`border-round-lg p-3  
                                    ${msg.user_id === messages.user_id ? 'bg-gray-800' : 'bg-teal-700'}`}
                                                style={{ width: '50%' }}
                                            >
                                                <div>
                                                    <p className="text-xl mb-2 font-semibold text-white">{msg.user}</p>
                                                    <p className="text-white py-2 px-1 border-round-sm w-auto" style={{ backgroundColor: 'rgba(255,255,255,.1)', wordWrap: 'break-word', whiteSpace: 'pre-wrap', marginBottom: '10px' }}>
                                                        {msg.message}
                                                    </p>
                                                    <div className={`text-sm ${msg.user_id === messages.user_id ? 'text-right' : 'text-left'}`}>
                                                        <span className="text-white">{formatDateTime(msg.created_at)}</span>
                                                        {msg.user_id !== messages.user_id && (
                                                            <>
                                                                {msg.viewed === 1 ? (
                                                                    <>
                                                                        <p className={`pi pi-check text-primary mb-0 ml-3 ${msg.user_id === messages.user_id ? 'text-left' : 'text-right'}`}></p>
                                                                        <p className={`pi pi-check text-primary mb-0 ${msg.user_id === messages.user_id ? 'text-left' : 'text-right'}`}></p>
                                                                    </>
                                                                ) : (
                                                                    <p className={`pi pi-check text-gray-400 mb-0 ml-3 ${msg.user_id === messages.user_id ? 'text-left' : 'text-right'}`}></p>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <p className="text-center card">Henüz mesaj yok. Sohbete başlamak için bir mesaj yazın.</p>
                        )}
                    </div>
                </div>
            )}
        </Dialog>
    );
};

export default Chat;
