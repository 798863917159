import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllRoles, createRole, updateRole, deleteRole, getPermissions, setPermissions, getAllPermissions, getById } from '../../service/request/rolesRequest';

export const getAllRolesAsyncThunk = createAsyncThunk('roles/getAll', async () => {
    const response = await getAllRoles();
    return response.data;
});

export const createRoleAsyncThunk = createAsyncThunk('roles/create', async (role) => {
    const response = await createRole(role);
    return response.data;
});

export const updateRoleAsyncThunk = createAsyncThunk('roles/update', async (role) => {
    const response = await updateRole(role);
    return response.data;
});

export const deleteRoleAsyncThunk = createAsyncThunk('roles/delete', async (id) => {
    const response = await deleteRole(id);
    return id;
});

export const getPermissionsAsyncThunk = createAsyncThunk('roles/getPermissions', async (id) => {
    const response = await getPermissions(id);
    return response.data;
});

export const setPermissionsAsyncThunk = createAsyncThunk('roles/setPermissions', async (data) => {
    const response = await setPermissions(data);
    return response.data;
});

export const getAllPermissionsAsyncThunk = createAsyncThunk('roles/getAllPermissions', async () => {
    const response = await getAllPermissions();
    return response.data;
});

export const getByIdAsyncThunk = createAsyncThunk('roles/getById', async (id) => {
    const response = await getById(id);
    return response.data;
});

export const roleSlice = createSlice({
    name: 'roles',
    initialState: {
        roles: [],
        status: null,
        error: null,
        toastMessage: null,
        userPermissions: [],
        allPermissions: [],
        selectedUserPermissions: []
    },

    reducers: {
        clearToast: (state) => {
            state.status = null;
            state.error = null;
            state.toastMessage = null;
        }
    },

    extraReducers(builder) {
        builder
            .addCase(getAllRolesAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllRolesAsyncThunk.fulfilled, (state, action) => {
                state.roles = action.payload.data;
                state.status = '';
            })
            .addCase(getAllRolesAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createRoleAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createRoleAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.roles.push(action.payload.data);
                state.toastMessage = 'Rol başırılı bir şekilde oluşturuldu.';
            })
            .addCase(createRoleAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Rol oluşturulurken bir hata oluştu.';
            })
            .addCase(updateRoleAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateRoleAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                const index = state.roles.findIndex((role) => role.id === action.payload.data.id);
                state.roles[index] = action.payload.data;
                state.toastMessage = 'Rol başırılı bir şekilde güncellendi.';
            })
            .addCase(updateRoleAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Rol güncellenirken bir hata oluştu.';
            })
            .addCase(deleteRoleAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteRoleAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.roles = state.roles.filter((role) => role.id !== action.payload);
                state.toastMessage = 'Rol başırılı bir şekilde silindi.';
            })
            .addCase(deleteRoleAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Rol silinirken bir hata oluştu.';
            })
            .addCase(getPermissionsAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPermissionsAsyncThunk.fulfilled, (state, action) => {
                state.userPermissions = action.payload.data;

                const selectedKeys = {};
                action.payload.data.forEach((permission) => {
                    selectedKeys[permission.id] = { checked: true };
                });
                state.selectedUserPermissions = selectedKeys;
                state.status = '';
            })
            .addCase(getPermissionsAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Rol yetkileri getirilirken bir hata.';
            })
            .addCase(setPermissionsAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(setPermissionsAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.userPermissions = action.payload.data;
                state.toastMessage = 'Rol yetkileri başırılı bir şekilde güncellendi.';
            })
            .addCase(setPermissionsAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Rol yetkileri güncellenirken bir hata.';
            })
            .addCase(getAllPermissionsAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllPermissionsAsyncThunk.fulfilled, (state, action) => {
                state.allPermissions = action.payload.data;
                state.status = '';
            })
            .addCase(getAllPermissionsAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Tüm yetkiler getirilirken bir hata.';
            })
            .addCase(getByIdAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getByIdAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.roles = action.payload.data;
                state.toastMessage = 'Rol başırılı bir şekilde getirildi.';
            })
            .addCase(getByIdAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Rol getirilirken bir hata.';
            });
    }
});

export const { clearToast } = roleSlice.actions;

export default roleSlice.reducer;
