import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useSelector, useDispatch } from 'react-redux';
import {
    clearToast,
    createRoleAsyncThunk,
    deleteRoleAsyncThunk,
    getAllPermissionsAsyncThunk,
    getAllRolesAsyncThunk,
    getPermissionsAsyncThunk,
    setPermissionsAsyncThunk
} from '../../store/roles/rolesSlice';
import Loading from '../module/parasut/components/Loading';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tree } from 'primereact/tree';
import { Toast } from 'primereact/toast';
import { usePermissionContext } from '../../context/usePermissionContext';

import { permissionsCount, getPermissionNodes, getPermissionIds } from './utils/util';

const RolesPage = () => {
    const [globalFilter, setGlobalFilter] = useState(null);
    const [roleDialog, setRoleDialog] = useState(false);
    const [roleDeleteDialog, setRoleDeleteDialog] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);

    const [nodes, setNodes] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState(null);
    const [createDialogVisible, setCreateDialogVisible] = useState(false);
    const [roleName, setRoleName] = useState('');

    const { roles, allPermissions, selectedUserPermissions, toastMessage, status } = useSelector((state) => state.roles);
    const { controlPermission } = usePermissionContext();

    const toast = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (status === 'success') {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: toastMessage, life: 3000 });
            dispatch(clearToast());
        } else if (status === 'failed') {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: toastMessage, life: 3000 });
            dispatch(clearToast());
        }
    }, [status, toastMessage, dispatch]);

    useEffect(() => {
        if (roles.length === 0) {
            dispatch(getAllRolesAsyncThunk());
        }

        if (allPermissions.length === 0) {
            dispatch(getAllPermissionsAsyncThunk());
        }
    }, [roles, allPermissions, dispatch]);

    useEffect(() => {
        setSelectedKeys(selectedUserPermissions);
    }, [selectedUserPermissions]);

    useEffect(() => {
        if (allPermissions.length > 0) {
            setNodes(getPermissionNodes(allPermissions));
        }
    }, [allPermissions]);

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Roller</h4>
            <div>
                {controlPermission("roles.create") &&
                    <Button label="Rol Ekle"
                        icon="pi pi-plus-circle"
                        className="p-button-primary text-white mr-4 mb-0"
                        onClick={() => setCreateDialogVisible(true)}
                    />
                }
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {
                    (controlPermission("roles.getPermissions") || controlPermission("roles.getAllPermissions")) &&
                    <Button
                        className="mr-2"
                        icon="pi pi-eye"
                        tooltip="Yetkiler"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        onClick={() => handlePermissionDialog(rowData)}

                    />}
                {controlPermission("roles.delete") &&
                    <Button
                        icon="pi pi-trash"
                        tooltip="Sil"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        severity="danger"
                        onClick={() => {
                            setRoleDeleteDialog(true);
                            setSelectedRole(rowData);
                        }}
                    />}
            </React.Fragment>
        );
    };

    const deleteRole = async () => {
        setRoleDeleteDialog(false);
        await dispatch(deleteRoleAsyncThunk(selectedRole.id));
        setSelectedRole(null);
    };

    const handlePermissionDialog = async (rowData) => {
        setSelectedRole(rowData);
        await dispatch(getPermissionsAsyncThunk(rowData.id));
        setRoleDialog(true);
    };

    const save = async () => {
        let checkedIds = getPermissionIds(selectedKeys);
        await dispatch(setPermissionsAsyncThunk({ id: selectedRole.id, permissions: checkedIds }));
        setRoleDialog(false);
    };

    const permissionDialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" outlined onClick={() => setRoleDialog(false)} />
            {(controlPermission("roles.update") || controlPermission("roles.setPermissions")) && <Button label="Yetkilendir" icon="pi pi-user-plus" onClick={() => save()} type="submit" />}
        </React.Fragment>
    );

    const deleteDialogFooter = (
        <React.Fragment>
            <Button label="Hayır" icon="pi pi-times" outlined onClick={() => setRoleDeleteDialog(false)} />
            <Button label="Evet" icon="pi pi-check" severity="danger" onClick={() => deleteRole()} />
        </React.Fragment>
    );

    const createDialogFooter = (
        <React.Fragment>
            <Button label="Hayır" icon="pi pi-times" outlined onClick={() => setCreateDialogVisible(false)} />
            <Button label="Oluştur" icon="pi pi-check" severity="primary" onClick={() => createRole()} disabled={roleName === ""} />
        </React.Fragment>
    );

    const createRole = async () => {
        setCreateDialogVisible(false);
        await dispatch(createRoleAsyncThunk({ name: roleName }));
        setRoleName('');
    };

    if (roles.length === 0) {
        return <Loading />;
    }

    return (
        <div>
            <Toast ref={toast} />
            <div>
                <div className="card">
                    <DataTable
                        value={roles}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{totalRecords} rollerden {first} ila {last} arası gösteriliyor"
                        globalFilter={globalFilter}
                        emptyMessage="Rol Bulunamadı"
                        header={header}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header="Rol Adı" style={{ minWidth: '12rem' }} sortable></Column>
                        <Column field="created_at" header="Oluşturulma Tarihi" body={(rowData) => rowData.created_at.replaceAll("-", ".")} style={{ minWidth: '12rem' }} sortable></Column>
                        <Column header="İşlemler" body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    </DataTable>
                </div>

                <Dialog
                    header="Rol Yetkileri"
                    visible={roleDialog}
                    style={{ width: '40vw' }}
                    modal
                    footer={permissionDialogFooter}
                    onHide={() => {
                        setRoleDialog(false);
                        setSelectedKeys(null);
                    }}
                >
                    <span className="p-badge p-badge-success text-white mb-3">{permissionsCount(selectedKeys)} tane yetki seçildi.</span>
                    <div className="card flex justify-content-center">
                        <Tree value={nodes} selectionMode="checkbox" selectionKeys={selectedKeys} onSelectionChange={(e) => setSelectedKeys(e.value)} className="w-full md:w-40rem border-none" />
                    </div>
                </Dialog>

                <Dialog
                    visible={roleDeleteDialog}
                    style={{ width: '32rem' }}
                    breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                    header="Silme" modal
                    footer={deleteDialogFooter}
                    onHide={() => setRoleDeleteDialog(false)}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        <span>
                            <b>{selectedRole?.name}</b> Rolünü silmek istediğinize emin misiniz ?
                        </span>
                    </div>
                </Dialog>

                <Dialog visible={createDialogVisible} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Rol Oluşturma" modal footer={createDialogFooter} onHide={() => { setCreateDialogVisible(false); setRoleName("") }}>
                    <div className="confirmation-content">
                        <div className="w-full mb-2">
                            <label>Rol Adı</label>
                        </div>
                        <div className="w-full">
                            <InputText className="w-full mb-4" type="text" value={roleName} onChange={(e) => setRoleName(e.target.value)} placeholder="Rol Adı" required />
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default RolesPage;
