import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'


export default function CommonDeleteDialog({ visible, header, submitLabel, submitIcon, content, onHide, onSubmit }) {
    if (!visible) {
        return null;
    }

    const deleteDialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" severity='secondary' outlined onClick={() => onHide()} />
            <Button label={submitLabel} icon={submitIcon} severity='danger' onClick={(e) => onSubmit()} type="submit" />
        </React.Fragment>
    );

    return (
        <Dialog
            visible={visible}
            style={{ width: '32rem' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header={header}
            modal
            className="p-fluid"
            footer={deleteDialogFooter}
            onHide={() => onHide()}
        >
            <p>{ content }</p>
        </Dialog>
    )
}