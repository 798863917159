export const convertName = (req) => {
    switch (req) {
        case 'username':
            return 'Kullanıcı Adı';
        case 'password':
            return 'Şifre';
        case 'clientId':
            return 'Client ID';
        case 'clientSecret':
            return 'Client Secret';
        default:
            return req;
    }
};

export const convertReq = (req) => {
    let copy = {};
    req.forEach((req) => {
        copy[req] = '';
    });

    return copy
}

export const getCompanyForDropdown = (data) => {
    let company = [];
    data.forEach((item) => {
        company.push({
            title: item.name,
            value: item.id
        });
    });
    return company;
}

export const deleteCompanyIsHere = (data, company) => {
    let copy = [];
    data.forEach((item) => {
        if (company.find(c => c.id === item.value) === undefined) {
            copy.push(item);
        }
    });
    return copy;
}