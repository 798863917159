import React, { useState, useEffect, useRef } from 'react';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { clearRequirements, setModuleSettingsAsyncThunk } from '../../../store/modules/moduleSlice';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import LucaCompanyReq from './components/LucaCompanyReq';
import LucaDocCode from './components/LucaDocCode';
import Loading from './components/Loading';

import { updateCompanyReq } from '../../../store/modules/lucaSlice';

import { LucaRequest } from './utils/lucaRequest';
import { clearAllLuca, setCodeList } from '../../../store/modules/lucaSlice';
import { getCompanyForDropdown } from './utils/util';
import { getAllWebServicesByModuleId } from '../../../service/request/moduleRequest';
import ProgramReq from './components/ProgramReq';
import { portalDefterCheck } from '../datasoft/utils/DatasoftRequest';

export default function UpdateLuca() {
    const { selectedModule, selectedProgram, updatedModule, selectedCompany: selectedCompanySlice } = useSelector((state) => state.modules);
    const { companyReq, codeList, programReq } = useSelector((state) => state.luca);

    const [response, setResponse] = useState(null);
    const [companyList, setCompanyList] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [selectedWebService, setSelectedWebService] = useState(null);

    const [periodList, setPeriodList] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);

    const [loading, setLoading] = useState(false);

    const [isLoginLuca, setIsLoginLuca] = useState(false);
    const [loginError, setLoginError] = useState(null);

    const [dialogVisible, setDialogVisible] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [webServicesList, setWebServicesList] = useState([]);
    const [lucaCompanies, setLucaCompanies] = useState([]);

    const [pending, setPending] = useState(false);

    const [updateCompanyDialog, setUpdateCompanyDialog] = useState(false);
    const toast = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (updatedModule) {
            setLucaCompanies(updatedModule.settings.program.lucaCompanies);
        }
    }, [selectedModule, selectedProgram]);

    useEffect(() => {
        if (loginError) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: loginError, life: 3000 });

            setTimeout(() => {
                setLoginError(null);
            }, 3000);
        }
    }, [loginError]);

    useEffect(() => {
        if (updateCompanyDialog) {
            let company = lucaCompanies.find((item) => item.companyCode === String(selectedCompany));
            setSelectedWebService(company?.webService?._id);
            setUsername(company?.webService?.requirements?.username);
            setPassword(company?.webService?.requirements?.password);
        }
    }, [selectedCompany, lucaCompanies]);

    const getWebServices = async () => {
        const res = await getAllWebServicesByModuleId(updatedModule?.id);
        setWebServicesList(res.data.data);
        setLoading(false);
    };

    useEffect(() => {
        if (updatedModule) {
            getWebServices();
        }
    }, [dispatch]);

    const login = async (e) => {
        e.preventDefault();
        setLoading(true);

        const res = await LucaRequest.login(programReq.username, programReq.password, programReq.customerNumber);

        if (res.name === 'AxiosError') {
            setLoginError(res?.response?.data?.message);
        } else {
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Luca Girişi Başarılı.', life: 1500 });
            }, 100);
            setResponse(res);
            setCompanyList(getCompanyForDropdown(res));
            setSelectedCompany(null);
            setIsLoginLuca(true);
        }
        setLoading(false);
    };

    const handleChangeCompany = async (e) => {
        setSelectedCompany(e.value);
        let period = [];
        response.forEach((item) => {
            if (item.company.value === e.value) {
                item.periods.forEach((p) => {
                    period.push({
                        title: p.label,
                        value: p.value
                    });
                });
            }
        });
        setPeriodList(period);
    };

    const save = async () => {
        const data = {
            moduleId: updatedModule.id,
            companyId: updatedModule.pivot.company_id,
            settings: {
                moduleId: updatedModule.id,
                title: updatedModule.title,
                program: {
                    _id: updatedModule.settings.program._id,
                    title: updatedModule.settings.program.title,
                    requirements: [
                        {
                            username: programReq.username,
                            password: programReq.password,
                            customerNumber: programReq.customerNumber
                        }
                    ],
                    lucaCompanies: lucaCompanies
                }
            }
        };

        setPending(true);
        let res = await dispatch(setModuleSettingsAsyncThunk(data));

        if (res.meta.requestStatus === 'fulfilled') {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Modül Eklendi', life: 1500 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Modül Eklenemedi', life: 1500 });
        }

        setTimeout(() => {
            setPending(false);
            navigate(`/companies`);
            dispatch(clearRequirements());
        }, 500);
    };

    const handleCreateCompany = () => {
        const company = response.find((item) => item.company.value === selectedCompany);
        const period = company.periods.find((item) => item.value === selectedPeriod);
        const webServiceRequirements = webServicesList.find((item) => item.id === selectedWebService);

        const data = {
            companyName: company.company.label,
            companyCode: company.company.value,
            periodId: String(period.value),
            periodTitle: String(period.label),
            eDefterSubeAdi: companyReq[0].value,
            eDefterSubeKodu: companyReq[1].value,
            taxId: companyReq[2].value,
            belgeTurleri: codeList,
            webService: {
                _id: webServiceRequirements.id,
                title: webServiceRequirements.title,
                url: webServiceRequirements.url,
                requirements: {
                    username: username,
                    password: password
                }
            }
        };

        setLucaCompanies([...lucaCompanies, data]);

        setCompanyList(companyList.filter((item) => item.value !== selectedCompany));
        setDialogVisible(false);
        setSelectedCompany(null);
        setSelectedPeriod(null);
        setPeriodList(null);
        setUsername('');
        setPassword('');
        setSelectedWebService(null);
        dispatch(clearAllLuca());
        setTimeout(() => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Eklendi', life: 1500 });
        }, 100);
    };

    const handleDeleteCompany = (rowData) => {
        setLucaCompanies(lucaCompanies.filter((item) => item.companyCode !== rowData.companyCode));
        setCompanyList([...companyList, { title: rowData.companyName, value: rowData.companyCode }]);
        setTimeout(() => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Silindi', life: 1500 });
        }, 100);
    };

    const handleCreateDialog = () => {
        setSelectedCompany(null);
        setSelectedPeriod(null);
        setPeriodList(null);
        setUsername('');
        setPassword('');
        setSelectedWebService(null);
        dispatch(clearAllLuca());
        setDialogVisible(true);
    };

    const handleCheckPortal = async () => {
        const webService = webServicesList.find((item) => item.id === selectedWebService);

        const data = {
            url: webService.url,
            username: username,
            password: password
        };

        const res = await portalDefterCheck(data);

        if (res.data.success) {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Web servis kullanıcı bilgileriniz doğrudur', life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Web servis kullanıcı bilgileriniz hatalıdır', life: 3000 });
        }
    };

    const checkCreate = () => {
        return selectedCompany && selectedPeriod && companyReq[2].value && username && password && selectedWebService;
    };

    const dialogFooterTemplate = () => {
        return <Button label="Oluştur" icon="pi pi-check" className="p-button-info mt-3" onClick={() => handleCreateCompany()} disabled={!checkCreate()} />;
    };

    const dialogUpdateFooterTemplate = () => {
        return <Button label="Güncelle" icon="pi pi-check" className="p-button-info mt-3" onClick={() => handleUpdateCompany()} disabled={!checkCreate()} />;
    };

    const actionCodeTemplate = (rowData) => {
        return (
            <>
                <Button icon="pi pi-pencil" tooltip="Düzenle" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded outlined severity="primary" onClick={() => handleChangeUpdateCompany(rowData)} />
                <Button icon="pi pi-trash" tooltip="Sil" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} className="ml-2" rounded outlined severity="danger" onClick={() => handleDeleteCompany(rowData)} />
            </>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Şirketler</h4>
            <div>
                <Button label="Şirket Ekle" icon="pi pi-plus-circle" className="p-button-primary text-white mr-4 mb-0" onClick={() => handleCreateDialog()} disabled={!isLoginLuca} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const handleChangeUpdateCompany = (rowData) => {
        setSelectedCompany(Number(rowData.companyCode));
        dispatch(setCodeList(rowData.belgeTurleri));

        dispatch(updateCompanyReq({ title: 'E Defter Şube Adı', value: rowData.eDefterSubeAdi || '' }));
        dispatch(updateCompanyReq({ title: 'E Defter Şube Kodu', value: rowData.eDefterSubeKodu || '' }));
        dispatch(updateCompanyReq({ title: 'Vergi Numarası', value: rowData.taxId }));

        let period = [];

        response.forEach((item) => {
            if (item.company.value === rowData.companyCode) {
                item.periods.forEach((p) => {
                    period.push({
                        title: p.label,
                        value: p.value
                    });
                });
            }
        });

        setPeriodList(period);
        setSelectedPeriod(Number(rowData.periodId));
        setUpdateCompanyDialog(true);
    };

    const handleUpdateCompany = () => {
        const company = lucaCompanies.find((item) => item.companyCode === String(selectedCompany));
        const webServiceRequirements = webServicesList.find((item) => item.id === selectedWebService);
        const data = {
            companyName: company.companyName,
            companyCode: company.companyCode,
            periodId: String(selectedPeriod),
            periodTitle: String(periodList.find((item) => item.value === selectedPeriod).title),
            eDefterSubeAdi: companyReq[0].value,
            eDefterSubeKodu: companyReq[1].value,
            taxId: companyReq[2].value,
            belgeTurleri: codeList,
            webService: {
                _id: webServiceRequirements.id,
                title: webServiceRequirements.title,
                url: webServiceRequirements.url,
                requirements: {
                    username: username,
                    password: password
                }
            }
        };

        setLucaCompanies(
            lucaCompanies.map((item) => {
                if (item.companyCode === String(selectedCompany)) {
                    return data;
                }
                return item;
            })
        );

        setCompanyList(companyList.filter((item) => item.value !== String(selectedCompany)));

        setSelectedCompany(null);
        setSelectedPeriod(null);
        setPeriodList(null);
        dispatch(clearAllLuca());

        setUpdateCompanyDialog(false);
        setSelectedWebService(null);
        setUsername('');
        setPassword('');
        setTimeout(() => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Güncellendi', life: 1500 });
        }, 100);
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const changeWebServices = (e) => {
        setUsername('');
        setPassword('');
        setSelectedWebService(e.value);
    };

    const filterSelectedCompany = () => {
        const lucaCompanyCode = lucaCompanies.map((item) => item.companyCode);
        return companyList.filter((item) => !lucaCompanyCode.includes(item.value));
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="flex align-items-center">
                <Button className="" icon="pi pi-building" />
                <h3 className="mb-4 ml-3">{selectedCompanySlice?.name}</h3>
            </div>
            <div className="card">
                <div>
                    <div className="flex flex-column md:flex-row">
                        <ProgramReq req={updatedModule?.settings?.program?.requirements[0]} title={updatedModule?.settings?.program?.title} onLogin={login} isLogin={isLoginLuca} loading={loading} />
                    </div>
                </div>
                <Dialog header="Şirket Ekle" visible={dialogVisible} style={{ width: '50vw' }} maximizable modal onHide={() => setDialogVisible(false)} footer={dialogFooterTemplate}>
                    <div className="mt-4">
                        {isLoginLuca && (
                            <>
                                <label className="mb-2 block">Şirketler</label>
                                <Dropdown
                                    dataKey="_id"
                                    value={selectedCompany}
                                    onChange={handleChangeCompany}
                                    options={filterSelectedCompany() || []}
                                    optionLabel="title"
                                    placeholder="Şirket Seçiniz"
                                    filter
                                    className="w-full mb-4"
                                    emptyMessage="Şirket Bulunamadı"
                                    appendTo="self"
                                />
                                {periodList && (
                                    <div className="mb-3">
                                        <label className="mb-2 block">Periyotlar</label>
                                        <Dropdown
                                            dataKey="id"
                                            value={selectedPeriod}
                                            onChange={(e) => setSelectedPeriod(e.value)}
                                            options={periodList}
                                            optionLabel="title"
                                            placeholder="Periyot Seçiniz"
                                            filter
                                            className="w-full mb-2"
                                            emptyMessage="Periyot Bulunamadı"
                                            appendTo="self"
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        <div>
                            <div className="w-full mb-2">
                                <label>Web Servis Programları</label>
                            </div>
                            <div>
                                <Dropdown
                                    dataKey="id"
                                    value={selectedWebService}
                                    onChange={(e) => changeWebServices(e)}
                                    options={webServicesList?.map((item) => ({ value: item.id, label: item.title })) || []}
                                    optionLabel="label"
                                    placeholder="Web Servis Seçiniz"
                                    filter
                                    className="w-full mb-3"
                                    emptyMessage="Web Servis Bulunamadı"
                                    appendTo="self"
                                />
                            </div>
                        </div>
                        {selectedWebService && (
                            <div className="my-2">
                                <div className="w-full mb-2">
                                    <label>Web Servis Kullanıcı Adı</label>
                                </div>
                                <div className="w-full">
                                    <InputText className="w-full mb-4" type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Kullanıcı Adı" required />
                                </div>
                                <div className="w-full mb-2">
                                    <label>Web Servis Şifre</label>
                                </div>
                                <div className="flex w-full align-items-center gap-3">
                                    <InputText className="w-full" type="text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Şifre" required />
                                    <Button label="Şifreyi Kontrol Et" className="flex-shrink-0" onClick={handleCheckPortal} />
                                </div>
                            </div>
                        )}

                        <LucaCompanyReq />
                        <LucaDocCode />
                    </div>
                </Dialog>
                {lucaCompanies && isLoginLuca && (
                    <DataTable
                        value={lucaCompanies}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{totalRecords} şirketten {first} ila {last} arası gösteriliyor"
                        emptyMessage="Şirket Bulunamadı"
                        className="my-6"
                        header={header}
                        globalFilter={globalFilter}
                    >
                        <Column field="companyName" header="Şirket İsmi" sortable style={{ minWidth: '12rem' }}></Column>
                        <Column field="periodTitle" header="Periyot" sortable style={{ minWidth: '16rem' }}></Column>
                        <Column field="taxId" header="Vergi Numarası" sortable style={{ minWidth: '16rem' }}></Column>
                        <Column field="webService.title" header="Web Servis Adı" sortable style={{ minWidth: '16rem' }}></Column>
                        <Column header="İşlemler" body={actionCodeTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    </DataTable>
                )}
                <Dialog header="Şirket Güncelle" visible={updateCompanyDialog} style={{ width: '50vw' }} maximizable modal onHide={() => setUpdateCompanyDialog(false)} footer={dialogUpdateFooterTemplate}>
                    <div className="mt-4">
                        <label className="mb-2 block">Periyotlar</label>
                        <Dropdown
                            dataKey="id"
                            value={selectedPeriod}
                            onChange={(e) => setSelectedPeriod(e.value)}
                            options={periodList}
                            optionLabel="title"
                            placeholder="Periyot Seçiniz"
                            filter
                            className="w-full mb-4"
                            emptyMessage="Periyot Bulunamadı"
                        />
                        <div>
                            <div className="w-full mb-2">
                                <label>Web Servis Programları</label>
                            </div>
                            <div>
                                <Dropdown
                                    dataKey="id"
                                    value={selectedWebService}
                                    onChange={(e) => changeWebServices(e)}
                                    options={webServicesList?.map((item) => ({ value: item.id, label: item.title })) || []}
                                    optionLabel="label"
                                    placeholder="Web Servis Seçiniz"
                                    filter
                                    className="w-full mb-3"
                                    emptyMessage="Web Servis Bulunamadı"
                                    appendTo="self"
                                />
                            </div>
                        </div>
                        {selectedWebService && (
                            <div className="my-2">
                                <div className="w-full mb-2">
                                    <label>Web Servis Kullanıcı Adı</label>
                                </div>
                                <div className="w-full">
                                    <InputText className="w-full mb-4" type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Kullanıcı Adı" required />
                                </div>
                                <div className="w-full mb-2">
                                    <label>Web Servis Şifre</label>
                                </div>
                                <div className="flex w-full align-items-center gap-3">
                                    <InputText className="w-full" type="text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Şifre" required />
                                    <Button label="Şifreyi Kontrol Et" className="flex-shrink-0" onClick={handleCheckPortal} />
                                </div>
                            </div>
                        )}
                        <LucaCompanyReq />
                        <LucaDocCode />
                    </div>
                </Dialog>
                {isLoginLuca && (
                    <div className="text-center mt-5">
                        <Button label="Güncelle" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => save()} loading={pending} />
                    </div>
                )}
            </div>
        </>
    );
}
