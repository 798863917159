import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateCompanyReq } from '../../../../store/modules/lucaSlice';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

/*
Luca Şirketinden İstenen Bilgiler

> E Defter Şube Adı
> E Defter Şube Kodu
> Vergi Numarası

*/

export default function LucaCompanyReq() {
    const { companyReq } = useSelector((state) => state.luca);
    const dispatch = useDispatch();

    const handleInputChange = (e, title) => {
        if (title === 'E Defter Şube Kodu' && e.target.value.length > 4) return;

        dispatch(updateCompanyReq({ title, value: e.target.value }));
    };

    const checkInput = (title) => {
        if (title === 'E Defter Şube Kodu' && companyReq[1].value.length < 4 && companyReq[1].value.length > 0) {
            return 'p-invalid';
        }
        return null;
    };

    return (
        <>
            {companyReq.map((item, index) => (
                <div key={index} className="py-3">
                    <div className="w-full mb-2">
                        <label>{item.title}</label>
                    </div>
                    <div className="w-full">
                        <InputText type="text" className={classNames({ 'p-invalid': checkInput(item.title), 'w-full': true })} value={item.value} onChange={(e) => handleInputChange(e, item.title)} placeholder={item.title} required />
                        {checkInput(item.title) && <small className="p-error">{item.title} 4 haneli olmalıdır.</small>}
                    </div>
                </div>
            ))}
        </>
    );
}
