import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PermissionProvider } from './context/usePermissionContext';

import { getToken, getUser } from './service/tokenServices';
import { setIsAuthenticated, setStoreUser } from './store/auth/authSlice';

import App from './App';
import { Login } from './pages/Login';
import Loading from './pages/module/datasoft/components/Loading';

const AppWrapper = () => {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const setOptions = async () => {
        const token = getToken();
        const user = getUser();
        if (token || user) {
            dispatch(setIsAuthenticated(true));
            dispatch(setStoreUser(user));
        } else {
            dispatch(setIsAuthenticated(false));
            dispatch(setStoreUser(null));
        }
        setIsLoading(false);
    };

    useEffect(() => {
        setOptions();
    }, []);

    if (isLoading) {
        return (
            <div style={{ height: '100vh' }}>
                <Loading />
            </div>
        );
    }


    return (
        <Routes>
            {isAuthenticated ? (
                <>
                    <Route path="/login" element={<Navigate to="/dashboard" />} />
                    <Route
                        path="/*"
                        element={
                            <PermissionProvider>
                                <App />
                            </PermissionProvider>
                        }
                    />
                </>
            ) : (
                <>
                    <Route path="/login" element={<Login />} />
                    <Route path="/*" element={<Navigate to="/login" />} />
                </>
            )}
        </Routes>
    );
};

export default AppWrapper;
